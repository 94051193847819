enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  REGISTER_COMPANY = "registerCompany",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  RESET_PASSWORD = "resetPassword",
  RESET_EMAIL_TOKEN = "resetEmail",
  ACTIVATION_ACCOUNT = "activationAccount",
  GET_JOBS = "getJobsByFilter",
  GET_JOBS_META = "getJobsMeta",
  GET_JOB_DETAILS = "getJobsById",
  CHANGE_PASSWORD = "changePassword",
  DEACTIVATE_ACCOUNT = "deactivateAccount",
  CHANGE_EMAIL = "changeEmail",
  DELETE_ACCOUNT = "deleteAccount",
  APPLY_JOB = "applyJob",
  WORK_CATEGORY = "workAreas",
  WORK_SPECIALITY = "workSpeciality",
  WORK_TASK = "workTask",
  WORK_ROLE = "workRoles",
  UPDATE_PROFILE = "updateProfile",
  CREATE_JOB = "createJob",
  UPDATE_PICTURE_PROFILE = "updatePictureProfile",
  UPDATE_WORK_EXPERIENCE_ITEM = "updateWorkExperienceItem",
  DELETE_WORK_EXPERIENCE_ITEM = "deleteWorkExperienceItem",
  UPDATE_CERTIFICATION_ITEM = "updateCertificationItem",
  DELETE_CERTIFICATION_ITEM = "deleteCertificationItem",
  UPDATE_EDUCATION_ITEM = "updateEducationItem",
  DELETE_EDUCATION_ITEM = "deleteEducationItem",
  UPDATE_COURSE_ITEM = "updateCourseItem",
  DELETE_COURSE_ITEM = "deleteCourseItem",
  UPDATE_LANGUAGE_ITEM = "updateLanguageItem",
  DELETE_LANGUAGE_ITEM = "deleteLanguageItem",
  UPDATE_RECOMMENDATION_ITEM = "updateRecommendationItem",
  DELETE_RECOMMENDATION_ITEM = "deleteRecommendationItem",
  UPDATE_PROFILE_NIST_NICE = "updateProfileNistNice",
  UPDATE_JOB_NIST_NICE = "updateJobNistNice",
  DELETE_PICTURE_PROFILE = "deletePictureProfile",
  UPDATE_COMPANY = "updateCompany",
  ADD_JOB_CERTIFICATION_ITEM = "addJobCertificationItem",
  DELETE_JOB_CERTIFICATION_ITEM = "deleteJobCertificationItem",
  ADD_JOB_LANGUAGE_ITEM = "addJobLanguageItem",
  DELETE_JOB_LANGUAGE_ITEM = "deleteJobLanguageItem",
  UPDATE_JOB = "updateJob",
  GET_COMPANY_JOBS = "getCompanyJobs",
  DELETE_JOB = "deleteJob",
  GET_TOP_TALENT_JOB = "getTopTalentJob",
  GET_TOP_TALENT_JOB_FILTER = "getTopTalentJobFilter",
  REGISTER_EMPLOYEE = "registerEmployee",
  DELETE_COMPANY_USERS = "deleteCompanyUsers",
  CONTACT_TALENT = "contactTalent",
  GET_CURRENT_TALENT_DETAILS = "getCurrentTalentDetails",
  GET_CURRENT_SUBSCRIPTION = "getCurrentSubscription",
  GET_SUBSCRIPTION_URL = "getSubscriptionUrl",
  GET_TRAININGS_LIST = "getTrainingsList",
  GET_TRAININGS_LIST_FILTER = "getTrainingsListFilter",
  GET_TRAININGS_LIST_FILTER_PROVIDER = "getTrainingsListFilterProvider",
  GET_TRAININGS_LIST_FILTER_LEVEL = "getTrainingsListFilterLevel",
  GET_TRAINING_DETAILS = "getTrainingDetails",
  GET_COURSE_DETAILS = "getCourseDetails",
  TAKE_TRAINING_REQUEST = "takeTrainingRequest",
  TAKE_SUBSCRIPTION_REQUEST = "takeSubscriptionRequest",
  COMPLETE_TRAINING = "completeTraining",
  GET_TALENT_LIST = "getTalentList",
  UPDATE_TOPTALENT = "updateTopTalent",
  CAREER_PATH = "CareerPath",
  CAREERS = "Careers",
  GET_CAREER_TRAININGS = "getCareerTrainings",
}

enum Mutations {
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_MESSAGE = "setMessage",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_AUTH_TOKEN = "setAuthToken",
  SET_JOBS = "filteredJobs",
  GET_JOBS_META_MUTATION = "filterJobsMutation",
  JOB_DETAILS = "getJobsdetailsMutation",
  CHANGE_PASSWORD = "changePassword",
  CHANGE_EMAIL = "changeEmail",
  SET_APPLY_JOB = "setApplyJob",
  SET_WORK_CATEGORY = "setWorkCategories",
  SET_WORK_TASKS = "setWorkTask",
  SET_WORK_ROLES = "setWorkRoles",
  SET_WORK_SPECIALTY = "setWorkSpecialty",
  UPDATE_PROFILE_MUTATION = "updateProfileMutation",
  UPDATE_WORK_EXPERIENCE_ITEM_MUTATION = "updateWorkExperienceItemMutation",
  DELETE_WORK_EXPERIENCE_ITEM_MUTATION = "deleteWorkExperienceItemMutation",
  UPDATE_CERTIFICATION_ITEM_MUTATION = "updateCertificationItemMutation",
  ADD_JOB_CERTIFICATION_ITEM_MUTATION = "addJobCertificationItemMutation",
  DELETE_CERTIFICATION_ITEM_MUTATION = "deleteCertificationItemMutation",
  DELETE_JOB_CERTIFICATION_ITEM_MUTATION = "deleteJobCertificationItemMutation",
  UPDATE_EDUCATION_ITEM_MUTATION = "updateEducationItemMutacion",
  DELETE_EDUCATION_ITEM_MUTATION = "deleteEducationItemMutacion",
  UPDATE_COURSE_ITEM_MUTATION = "updateCourseItemMutacion",
  DELETE_COURSE_ITEM_MUTATION = "deleteCourseItemMutacion",
  UPDATE_LANGUAGE_ITEM_MUTATION = "updateLanguageItemMutacion",
  ADD_JOB_LANGUAGE_ITEM_MUTATION = "addJobLanguageItemMutacion",
  DELETE_JOB_LANGUAGE_ITEM_MUTATION = "deleteJobLanguageItemMutacion",
  DELETE_LANGUAGE_ITEM_MUTATION = "deleteLanguageItemMutacion",
  UPDATE_RECOMMENDATION_ITEM_MUTATION = "updateRecommendationItemMutacion",
  DELETE_RECOMMENDATION_ITEM_MUTATION = "deleteRecommendationItemMutacion",
  UPDATE_NISTNICE_SPECIALITY = "updateNistNiceSpeciality",
  UPDATE_NISTNICE_TASK = "updateNistNiceTask",
  MREGE_CATEGORIES_NIST_NICE = "mergeCategoriesNistNice",
  MREGE_SPECIALITIES_NIST_NICE = "mergeSpecialitiesNistNice",
  MREGE_TASKS_NIST_NICE = "mergeTasksNistNice",
  MREGE_NIST_NICE = "mergeNistNice",
  UPDATE_CURRENT_ROLE = "updateCurrentRole",
  UPDATE_CURRENT_SUBSCRIPTION = "updateCurrentSubscription",
  CREATE_JOB_MUTATION = "createJobMutation",
  UPDATE_CURRENT_JOB = "updateCurrentJob",
  DELETE_JOB_MUTATION = "deleteJobMutation",
  GET_TOP_TALENT_JOB_MUTATION = "getTopTalentJobMutation",
  SET_STORE_MUTATION = "setStoreMutation",
  UPDATE_COMPANY_USERS = "updateCompanyUsers",
  DELETE_COMPANY_USERS_MUTATION = "deleteCompanyUsersMutation",
  UPDATE_CURRENT_EMPLOYEE = "updateCurrentEmployee",
  UPDATE_CURRENT_TALENT_DETAILS = "updateCurrentTalentDetails",
  GET_CURRENT_SUBSCRIPTION_MUTATION = "getCurrentSubscriptionMutation",
  GET_SUBSCRIPTION_URL_MUTATION = "getSubscriptionUrlMutation",
  GET_TRAININGS_LIST_MUTATION = "getTrainingsListMutation",
  GET_TRAININGS_LIST_FILTER_MUTATION = "getTrainingsListFilterMutation",
  GET_TRAININGS_LIST_FILTER_PROVIDER_MUTATION = "getTrainingsListFilterProviderMutation",
  GET_TRAININGS_LIST_FILTER_LEVEL_MUTATION = "getTrainingsListFilterLevelMutation",
  GET_TRAINING_DETAILS_MUTATION = "getTrainingDetailsMutation",
  SET_TRAINING_DETAILS_MUTATION = "SetTrainingDetailsMutation",
  GET_COURSE_DETAILS_MUTATION = "getCourseDetailsMutation",
  COMPLETE_TRAINING_MUTATION = "completeTrainingMutation",
  GET_TALENT_LIST_MUTATION = "getTalentListMutation",
  SUBSCRIPTION_SELECTED = "SUBSCRIPTION_SELECTED",
  SET_UPDATED_PASSWORD_ALERT_MUTATION = "setUpdatedPasswordAlert",
  SET_CAREER_PATH = "setCareerPath",
  SET_CAREERS = "setCareers",
  CAREER_PATH_COMPLETE = "selectedCareerPath",
  TRAININGS_CAREER_PATH_COMPLETE = "TrainingsCareerPathComplete",
}

export { Actions, Mutations };
