import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { nextTick } from "vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: () => {
      if (store.getters.currentUserRole !== "Talent") {
        if (store.getters.currentUserSubscriptionIsDemo) {
          return "/subscriptions";
        } else {
          return "/company/account/overview";
        }
      } else {
        return "/talent/account/overview";
      }
    },
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/SearchTalent",
        name: "talent-list",
        component: () => import("@/views/company/SearchTalent.vue"),
      },
      {
        path: "/company/account",
        name: "account-company",
        component: () => import("@/views/company/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview-company",
            component: () => import("@/views/company/account/Overview.vue"),
          },
          {
            path: "settings",
            name: "account-settings-company",
            component: () => import("@/views/company/account/Settings.vue"),
          },
          {
            path: "employees",
            name: "add-employees",
            component: () => import("@/views/company/account/AddEmployees.vue"),
          },
          {
            path: "subscriptions",
            name: "subscriptions-details",
            component: () =>
              import("@/views/company/account/SubscriptionsDetails.vue"),
          },
        ],
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/subscriptions",
        name: "subscriptions",
        component: () => import("@/views/subscriptions/index.vue"),
      },
      {
        path: "/jobs",
        name: "jobs",
        component: () => import("@/views/jobs/index.vue"),
      },
      {
        path: "/career",
        name: "career-path",
        component: () => import("@/views/careerPath/careers.vue"),
      },
      {
        path: "/career/details",
        name: "career-details",
        component: () => import("@/views/careerPath/careerDetails.vue"),
      },
      {
        path: "/trainings",
        name: "trainings",
        component: () => import("@/views/trainings/index.vue"),
      },
      {
        path: "/trainings/training",
        name: "training",
        component: () => import("@/views/trainings/training.vue"),
      },
      {
        path: "/trainings/training/detail",
        name: "training-detail",
        component: () => import("@/views/trainings/training-detail.vue"),
      },
      {
        path: "/trainings/training/detail/course",
        name: "training-detail-course",
        component: () => import("@/views/trainings/course.vue"),
      },
      {
        path: "/talent/profile",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () =>
              import("@/views/crafted/pages/profile/Overview.vue"),
          },
          {
            path: "projects",
            name: "profile-projects",
            component: () => import("@/views/talent/profile/Projects.vue"),
          },
          {
            path: "campaigns",
            name: "profile-campaigns",
            component: () =>
              import("@/views/crafted/pages/profile/Campaigns.vue"),
          },
          {
            path: "documents",
            name: "profile-documents",
            component: () =>
              import("@/views/crafted/pages/profile/Documents.vue"),
          },
          {
            path: "connections",
            name: "profile-connections",
            component: () =>
              import("@/views/crafted/pages/profile/Connections.vue"),
          },
          {
            path: "activity",
            name: "profile-activity",
            component: () =>
              import("@/views/crafted/pages/profile/Activity.vue"),
          },
        ],
      },
      {
        path: "/talent/account",
        name: "account",
        component: () => import("@/views/talent/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/talent/account/Overview.vue"),
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/talent/account/Settings.vue"),
          },
        ],
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/Builder.vue"),
      },
      {
        path: "/crafted/pages/profile",
        name: "profile-2",
        component: () => import("@/components/page-layouts/Profile.vue"),
        children: [
          {
            path: "overview",
            name: "profile-overview-2",
            component: () =>
              import("@/views/crafted/pages/profile/Overview.vue"),
          },
          {
            path: "projects",
            name: "profile-projects-2",
            component: () =>
              import("@/views/crafted/pages/profile/Projects.vue"),
          },
          {
            path: "campaigns",
            name: "profile-campaigns-2",
            component: () =>
              import("@/views/crafted/pages/profile/Campaigns.vue"),
          },
          {
            path: "documents",
            name: "profile-documents-2",
            component: () =>
              import("@/views/crafted/pages/profile/Documents.vue"),
          },
          {
            path: "connections",
            name: "profile-connections-2",
            component: () =>
              import("@/views/crafted/pages/profile/Connections.vue"),
          },
          {
            path: "activity",
            name: "profile-activity-2",
            component: () =>
              import("@/views/crafted/pages/profile/Activity.vue"),
          },
        ],
      },
      {
        path: "/crafted/pages/wizards/horizontal",
        name: "horizontal-wizard",
        component: () => import("@/views/crafted/pages/wizards/Horizontal.vue"),
      },
      {
        path: "/crafted/pages/wizards/vertical",
        name: "vertical-wizard",
        component: () => import("@/views/crafted/pages/wizards/Vertical.vue"),
      },
      {
        path: "/crafted/account",
        name: "account-2",
        component: () => import("@/views/crafted/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview-2",
            component: () => import("@/views/crafted/account/Overview.vue"),
          },
          {
            path: "settings",
            name: "account-settings-2",
            component: () => import("@/views/crafted/account/Settings.vue"),
          },
        ],
      },
      {
        path: "/apps/customers/getting-started",
        name: "apps-customers-getting-started",
        component: () => import("@/views/apps/customers/GettingStarted.vue"),
      },
      {
        path: "/apps/customers/customers-listing",
        name: "apps-customers-listing",
        component: () => import("@/views/apps/customers/CustomersListing.vue"),
      },
      {
        path: "/apps/customers/customer-details",
        name: "apps-customers-details",
        component: () => import("@/views/apps/customers/CustomerDetails.vue"),
      },
      {
        path: "/apps/subscriptions/getting-started",
        name: "apps-subscriptions-getting-started",
        component: () =>
          import("@/views/apps/subscriptions/GettingStarted.vue"),
      },
      {
        path: "/apps/subscriptions/subscription-list",
        name: "apps-subscriptions-subscription-list",
        component: () =>
          import("@/views/apps/subscriptions/SubscriptionList.vue"),
      },
      {
        path: "/apps/subscriptions/add-subscription",
        name: "apps-subscriptions-add-subscription",
        component: () =>
          import("@/views/apps/subscriptions/AddSubscription.vue"),
      },
      {
        path: "/apps/subscriptions/view-subscription",
        name: "apps-subscriptions-view-subscription",
        component: () =>
          import("@/views/apps/subscriptions/ViewSubscription.vue"),
      },
      {
        path: "/apps/calendar",
        name: "apps-calendar",
        component: () => import("@/views/apps/Calendar.vue"),
      },
      {
        path: "/apps/chat/private-chat",
        name: "apps-private-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/group-chat",
        name: "apps-group-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/drawer-chat",
        name: "apps-drawer-chat",
        component: () => import("@/views/apps/chat/DrawerChat.vue"),
      },
      // {
      //   path: "/crafted/modals/general/invite-friends",
      //   name: "modals-general-invite-friends",
      //   component: () =>
      //     import("@/views/crafted/modals/general/InviteFriends.vue"),
      // },
      {
        path: "/crafted/modals/general/view-user",
        name: "modals-general-view-user",
        component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
      },
      {
        path: "/crafted/modals/general/upgrade-plan",
        name: "modals-general-upgrade-plan",
        component: () =>
          import("@/views/crafted/modals/general/UpgradePlan.vue"),
      },
      {
        path: "/crafted/modals/general/share-and-earn",
        name: "modals-general-share-and-earn",
        component: () =>
          import("@/views/crafted/modals/general/ShareAndEarn.vue"),
      },
      {
        path: "/crafted/modals/forms/new-target",
        name: "modals-forms-new-target",
        component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
      },
      {
        path: "/crafted/modals/forms/new-card",
        name: "modals-forms-new-card",
        component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
      },
      {
        path: "/crafted/modals/forms/new-address",
        name: "modals-forms-new-address",
        component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
      },
      {
        path: "/crafted/modals/forms/create-api-key",
        name: "modals-forms-create-api-key",
        component: () =>
          import("@/views/crafted/modals/forms/CreateApiKey.vue"),
      },
      {
        path: "/crafted/modals/wizards/two-factor-auth",
        name: "modals-wizards-two-factor-auth",
        component: () =>
          import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-app",
        name: "modals-wizards-create-app",
        component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-account",
        name: "modals-wizards-create-account",
        component: () =>
          import("@/views/crafted/modals/wizards/CreateAccount.vue"),
      },
      {
        path: "/crafted/widgets/lists",
        name: "widgets-list",
        component: () => import("@/views/crafted/widgets/Lists.vue"),
      },
      {
        path: "/crafted/widgets/statistics",
        name: "widgets-statistics",
        component: () => import("@/views/crafted/widgets/Statistics.vue"),
      },
      {
        path: "/crafted/widgets/charts",
        name: "widgets-charts",
        component: () => import("@/views/crafted/widgets/Charts.vue"),
      },
      {
        path: "/crafted/widgets/mixed",
        name: "widgets-mixed",
        component: () => import("@/views/crafted/widgets/Mixed.vue"),
      },
      {
        path: "/crafted/widgets/tables",
        name: "widgets-tables",
        component: () => import("@/views/crafted/widgets/Tables.vue"),
      },
      {
        path: "/crafted/widgets/feeds",
        name: "widgets-feeds",
        component: () => import("@/views/crafted/widgets/Feeds.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/sign-up-company",
        name: "sign-up-company",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUpCompany.vue"),
      },
      {
        path: "/auth/reset/",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/change/",
        name: "change-email",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/ChangeEmail.vue"),
      },
      {
        path: "/password-recovery",
        name: "password-recovery",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/PasswordRecovery.vue"
          ),
      },
      {
        path: "/account/confirm_account/",
        name: "account-activation",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/Activation.vue"),
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  //if (!store.getters.currentUserRole) {
  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });
  //}

  if (
    to.fullPath.indexOf("/talent") === 0 &&
    store.getters.currentUserRole !== "Talent"
  ) {
    next("/company/account/overview");
  } else {
    //check by valid subscriptions in a company
    if (
      (to.fullPath.indexOf("/company") === 0 ||
        to.fullPath.indexOf("/jobs") === 0) &&
      // to.fullPath === "/" &&
      store.getters.currentUserRole !== "Talent" &&
      store.getters.currentUserSubscriptionIsDemo
    ) {
      store.commit(Mutations.RESET_LAYOUT_CONFIG);
      const layoutConfig = store.getters.layoutConfig();
      store.commit(Mutations.SET_LAYOUT_CONFIG, {
        ...layoutConfig,
        toolbar: {
          ...layoutConfig.sidebar,
          display: false,
        },
      });
      next("/subscriptions");
    }
    if (
      to.fullPath.indexOf("/subscriptions") === 0 &&
      !store.getters.currentUserSubscriptionIsDemo
    ) {
      next("/");
    }

    if (to.fullPath.indexOf("/subscriptions") === 0) {
      store.commit(Mutations.RESET_LAYOUT_CONFIG);
      const layoutConfig = store.getters.layoutConfig();
      store.commit(Mutations.SET_LAYOUT_CONFIG, {
        ...layoutConfig,
        toolbar: {
          ...layoutConfig.sidebar,
          display: false,
        },
      });
    } else {
      // reset config to initial state
      store.commit(Mutations.RESET_LAYOUT_CONFIG);
    }

    // Scroll page to top on every route change
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    next();
  }
});

export default router;
