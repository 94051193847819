import { createI18n } from "vue-i18n/index";

const messages = {
  en: {
    jobsView: {
      index: {
        create_new_pos_button: "Create new job position",
        edit_job_position_button: "Edit job position",
        apply_button: "Apply",
        go_Certifications_button: "Go to Certifications",
        go_Languages_button: "Go to Languages",
        continue_button: "Continue",
        ok_Got_It_button: "Ok, got it!",
        try_Again_button: "Try again!",
        go_Nist_Nice_button: "Go to NIST NICE",
        personal_information: "Personal information",
        jobs: "Jobs",
        expiration_Date: "Expiration Date",
        salary_Range: "Salary Range",
        view_Job: "View Job",
        please_Wait: "Please wait...",
        Required_Company: "If required by the company",
        experience_Deleted_msj: "The experience has been deleted.",
        deleting_Experience_msj: "We have an error deleting the experience.",
        your_Experiences_Have_msj:
          "Your experiences have been updated successfully.",
        certification_Been_Deleted_msj: "The certification has been deleted.",
        have_Deleting_Certification_msj:
          "We have an error deleting the certification.",
        have_SomeErrors: "We have some errors.",
        certification_Been_Updated_Successfully:
          "Your certification have been updated successfully.",
        please_Check_Red_Fields: "Please check the red fields.",
        roles_Succesfully_Updated:
          "Your NIST NICE roles have been succesfully updated!",
        invalid_Infromation: "invalid infromation.",
        full_Time: "Full-Time",
        part_Time: "Part-Time",
        self_Employed: "Self-Employed",
        freelance: "Freelance",
        contract: "Contract",
        internship: "Internship",
        apprenticeship: "Apprenticeship",
        seasonal: "Seasonal",
        not_Specified: "Not Specified",
        less_Than_High_School: "Less than high school",
        high_School_Diploma_Equivalent: "High school diploma or equivalent",
        oome_College_Degree: "Some college, no degree",
        postsecondary_Degree_Award: "Postsecondary non-degree award",
        associate_Degree: "Associate's degree",
        bachelor_Degree: "Bachelor's degree",
        master_Degree: "Master's degree",
        doctoral_Professional_Degree: "Doctoral or professional degree",
        //clear_filter_button: "Clear filter",
        salary_range:
          "The value entered in the Salary Range Max field must be greater than Salary Range Min.",
      },

      language: {
        ok_Got_It_button: "Ok, got it!",
        try_Again_button: "Try again!",
        language: "Language",
        select_Level: "Select a level",
        begginer: "begginer",
        knowledgeBasic:
          "Knowledge of basic vocabulary words, speaks simple sentences, elementary reading and writing skills.",
        intermediate: "Intermediate",
        handle_Intermediate:
          "Can handle intermediate communication, reading and writing.",
        proficient: "Proficient",
        well_Advanced:
          "A well advanced use of the language, using fluid speech, reading and writing.",
        native_Biligual: "Native/Biligual",
        more_fluent: "More than fluent in the language.",
        language_Been_Deleted: "The language has been deleted.",
        error_deleting_language: "We have an error deleting the language.",
        languages_Succesfully_Updated:
          "Your languages have been succesfully updated",
      },
    },
    plans: {
      PostJobs: "Post Jobs",
      ViewTopTalent: "View Top Talent",
      ContactTalent: "Contact Talent",
      TrainTalent: "Talent Training",
      JobsPostNumber: "Number of Jobs",
      JobPostDuration: "Job Duration",
      AdminCompanyUser: "Platform Users",
      RecommendedTalent: "Top Talent",
      SoftSkill: "Soft Skills",
      HardSkill: "Hard Skills",
      CareerDevelopment: "Career Development",
    },
    jobs: "Jobs",
    trainings: "Trainings",
    learning: "My Learning",
    accomplishments: "My Accomplishments",
    path: "My Path",
    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    multiStepSignUp: "Multi-steps Sign up",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
    my_Profile: "My Profile",
    language: "Language",
    accountSettings: "Account Settings",
    sign_Out: "Sign Out",
    on_Favorites: "On Favorites",
    on_Results: "On Results",
    recommendations: "Recommendations",
    profile_Completion: "Profile Completion",
    overview: "Overview",
    copyright_Seccuri2022: "Copyright Seccuri 2022",
    terms_Conditions: "Terms & Conditions",
    privacy_Statement: "Privacy Statement",
    profile_Details: "Profile Details",
    talent_Tittle1: "We need your attention!",
    talent_Subtittle1:
      "Please make sure to complete your Talent profile to get a better rating and allow Companies that are publishing jobs find you easier!",
    please_Get_Your_Roles: "Please get your roles!",
    to_get_Roles_Please_Follow:
      "To get the NIST NICE roles, please follow the 1, 2 and 3 steps.",
    you_Can_Start_With_First_Step:
      "You can start with the first step from here",
    select_your_Areas: "1. Select your Areas here",
    profile_Overview: "Profile Overview",
    completed_Sections: "Completed sections",
    home: "Home",
    full_Profile: "Full-Profile",

    trainingView: {
      index: {
        Status: "Status:",
        clear_filter_button: "Clear filter",
        search_placeholder: "Search",
        TRAINING: "TRAINING",
        CERTIFICATION: "CERTIFICATION",
        learn_New_Skills_Through:
          "Learn new skills through top trainings and certifactions to advance your CyberSec career!",
        pricing: "Pricing",
        not_Started: "Not Started",
        free: "Free",
        paid: "Paid",
        done: "Done",
        all_Careers: "All Careers",
        training_Provider: "Provider",
        level: "Level:",
        all_Roles: "All Roles",
        categories: "Categories",
        in_Progress: "In Progress",
        certification: "Certification",
        have_Security_Clearance: "have Security Clearance",
        Security_Clearance_required: "Security Clearance required",
        training: "Training",
        search: "Search",
        items_Found: "Items Found",
        by_Recently_Added: "by Recently Added ↓",
        recently_Updated: "Recently Added",
        last_Month: "Last Month",
        last_Quarter: "Last Quarter",
        last_Year: "Last Year",
        showing: "Showing",
        to: "to",
        of: "of",
        entries: "entries",
        by: "By:",
        author: "Author:",
        duration: "Duration:",
        modules: "Modules",
        regular_Price: "Regular price:",
        usd: "USD",
        seccuri_Price: "Seccuri Price",
        contact_Us: "Contact us",
        target_Of_Training: "Training Details",
        required_For: "Associated Skills",
        contact_Seccuri_Team: "Contact Seccuri Team",
        special_Prices: "Seccuri's special prices",
        complete_Training: "Training Completed",
        as_Completed: "as completed?",
        contact_For_Special_Seccuri_Price:
          "Contact Us for a special Seccuri price for this training!",
        take_This_Training: "Take This Training >",
        back_To_Trainings: "Back to trainings",
        check_Training_As_Completed: "Check training as completed",
        are_You_Interested_On_Taking_This_Training:
          "Are you interested on taking this training and gaining access to Seccuri's",
        by_Submitting_This_Request:
          "By submitting this request, we will be sending you an email with information on how to obtain and apply our promo code during this training's purchase process.",
        contact_To_Take_This_Training: "Contact to Take This Training",
        please_Wait: "Please wait...",
        are_You_Sure_You_Want_To_Mark: "Are you sure you want to mark",
        by_Submitting_This_Request_We_Will_Send:
          "By submitting this request, we will send you an email with information on how we will validate the completed training.",
        SelectProvider: "Select a Provider",
        SelectLevel: "Select a Level",
        SelectLocation: "Select Location",
        are_You_Interested_On_Taking_This_subscription:
          "Are you interested on subscribing",
        are_You_Interested_On_Taking_This_subscription_2:
          "and gaining access to",
        are_You_Interested_On_Taking_This_subscription_3: "Plan",
        by_Submitting_This_Request_We_Will_Send_subscription:
          "By submitting this request, we will send you an email with information on how we will validate the purchased process.",
        contact_To_Take_This_subscription: "Contact us for this Service",
        by_Submitting_This_Request_subscription:
          "By submitting this request, we will be sending you an email with information on how to obtain and apply our promo code during this Subscription's purchase process.",
      },
    },

    talentView: {
      index: {
        edit_Profile_button: "Edit Profile",
        next_Step_button: "Next Step",
        career: "Career",
        profile_Details: "Profile Details",
        roles: "Roles",
        education: "Education",
        certification: "Certification",
        courses: "Courses",
        recommendations: "Recommendations",
        personal_Information: "Personal Information",
        personal_Data: "Personal data",
        nIST_NICE: "NIST NICE",
        experience: "Experience",
        masters: "Masters",
        certifications: "Certifications",
        courses_Finished: "Courses finished",
        external_Recommendations: "External recommendations",
        area: "Securely Provision",
        overview: "Overview",
        account: "Account",
        is_Begginer: "New",
        is_BegginerDescription: "Cybersecurity Talent",
      },

      roles: {
        edit_Roles_button: "Edit your roles",
        select_Areas: "1. Select your Areas here",
        improve_Your_Roles: "Improve your roles!",
        can_Improve_The_Progress:
          "You can improve the progress of your roles at any moment by updating your areas, specialities and tasks by editing your roles",
        your_Nist_Nice_Roles: "Your NIST NICE Roles",
        your_Roles_And_Their_Progress:
          "Your roles and their progress are based on your selected specialities and tasks",
      },

      job: {
        back_button: "Back",
        submit_button: "Submit",
        job_Placeholder1:
          "Tell the company why you want to apply for this position here.",
        position_Details: "Position Details",
        basic_Information: "Basic information",
        nist_Nice_Roles: "NIST NICE Roles",
        specialities_Tasks: "Specialities and Tasks",
        certifications: "Certifications",
        certifications_Required: "Certifications Required",
        languages: "Languages",
        job_Title: "Job Title",
        company_Name: "Company Name",
        job_Department: "Job Department",
        job_Description: "Job Description",
        vacancy_Details: "Vacancy Details",
        salary_Range_Min: "Salary Range Min",
        salary_Range_Max: "Salary Range Max",
        employment_Type: "Employment Type",
        duration: "Duration",
        requirements: "Requirements",
        industry: "Industry",
        level_Education: "Level of Education",
        open_Travel: "Open to Travel",
        location: "Location",
        nationatily: "Nationatily",
        contact_Company: "Contact the Company",
        certification: "Certification",
        name: "Name",
        view_Position: "View Position",
        native_And_Learned_Languages_Required:
          "Native and learned languages required",
        units: "Units (Days, Months, etc.)",
        this_Job_Does_Not_Require_Certifications:
          "This job does not require any certifications.",
        please_Continue_Browsing_Through:
          "Please continue browsing through the next section.",
        nist_Nice_Roles_Required: "NIST NICE roles required",
        minimum_Percentage_Of_Nist_Nice_Roles:
          "Minimum percentage of NIST NICE roles required to apply to this job position",
        this_Position_Has_No_Specified:
          "This position has no specified Certifications yet!",
        this_Section_Provides_Information:
          "This section provides information about the certifications required by the Company to apply to this job.",
        this_Position_Has_No_specified_Languages:
          "This position has no specified languages yet!",
        provide_Information_About_Your_Languages:
          "Provide information about your languages.",
        use_The_Text_Box_Below:
          "Use the text box below to reach out to this Company.",
        by_Pressing_Submit_You_Consent:
          "By pressing Submit, you consent to share your profile information.",
        please_Wait: "Please wait...",
      },

      settings: {
        update_Email_button: "Update Email",
        cancel_button: "Cancel",
        change_Email_button: "Change Email",
        update_Password_button: "Update Password",
        reset_Password_button: "Reset Password",
        eliminate_Account_button: "Eliminate Account",
        placeholder_Email_Adress: "Email Address",
        placeholder_Confirm_Email_Address: "Confirm Email Address",
        email_Address: "Email Address",
        confirm_Email_Address: "Confirm New Email Address",
        new_Email_Address: "Enter New Email Address",
        confirm_Password: "Confirm Password",
        please_wait: "Please wait...",
        password: "Password",
        current_Password: "Current Password",
        new_Password: "New Password",
        confirm_New_Password: "Confirm New Password",
        confirm_Account_Elimination: "Confirm Account Elimination",
        enter_Email_Address: "Enter Email Address",
        sign_In_Method: "Sign-in Method",
        password_Must_Be_At_Least:
          "Password must be at least 8 character and contain symbols",
        you_Are_Deleting_Your_Account: "You are Deleting Your Account",
        by_Deleting_Your_Account:
          "By deleting your account, all the data and content associated to your profile will be lost.",
        for_Extra_Security:
          "For extra security, this requires you to confirm your email and password.",
      },

      details: {
        talent_Details: "Talent Details",
        contact: "Contact",
        basic_Information: "Basic Information",
        nist_Nice_Roles: "NIST NICE Roles",
        education: "Education",
        certifications: "Certifications",
        courses: "Courses",
        current_Entity: "Current Entity",
        current_Position: "Current Position",
        current_Location: "Current Location",
        nationality: "Nationality",
        seeking_Opportunities: "Seeking Opportunities",
        yes: "Yes",
        no: "No",
        open_Travel: "Open to Travel",
        desired_Employment_Type: "Desired Employment Type",
        highest_Level_Education: "Highest Level Of Education",
        job_Title: "Job Title",
        company: "Company",
        not_Specified: "Not specified",
        industry: "Industry",
        start_Date: "Start Date",
        end_Date: "End Date",
        currenty_Work_Here: "Currenty Work Here",
        certification_Name: "certification name",
        issuing_Entity: "Issuing Entity",
        date_Issued: "Date Issued",
        this_certification_Expires: "This certification expires",
        this_Certification_Not_Expires: "This certification not expires",
        degree: "Degree",
        school: "School",
        field_Study: "Field Of Study",
        graduation_Date: "Graduation Date",
        not_Graduated: "Not Graduated",
        activities: "Activities",
        name: "Name",
        number: "Number",
        associated_With: "Associated With",
      },
    },

    companyView: {
      index: {
        monthly_button: "Monthly",
        annual_button: "Annual",
        start_button: "Start",
        contact_us_button: "Contact us",
        subscribe_button: "Subscribe",
        edit_Profile_button: "Edit Profile",
        back_button: "Back",
        submit_button: "Submit",
        try_Again_button: "Try again!",
        ok_Got_It_button: "Ok, got it!",
        placeholder_Search: "Search",
        company_School_Name: "Company Name",
        security_clearance_level: "Security Clearance Level",
        contact_Number: "Contact Number",
        current_Position: "Current Position",
        country: "Country",
        seeking_Opportunities: "Seeking Opportunities",
        yes: "Yes",
        no: "No",
        company_Name: "Company Name",
        location: "Location",
        ceo: "CEO",
        company_Website: "Company Website",
        jobs: "Jobs",
        employees: "Employees",
        contacted_Candidates: "Contacted Candidates",
        profile_Completion: "Profile Completion",
        full_Profile: "Full-Profile",
        overview: "Overview",
        account: "Account",
        settings: "Settings",
        subscription: "Subscriptions",
        profile_Overview: "Profile Overview",
        completed_Sections: "Completed sections",
        company_Information: "Company Information",
        basic_data: "Basic Data",
        added_Employees: "Added Employees",
        created_Positions: "Created Positions",
        edit_Company_Profile: "Edit Company Profile",
        basic_Information: "Basic Information",
        company_Details: "Company Details",
        specified_Organization: "Specified Organization",
        contact_Information: "Contact Information",
        we_Need_Your_Attention: "We need your attention!",
        choose_Your_Plan: "Choose Your Plan",
        company_Profile_Details: "Company Profile Details",
        please_Make_Sure_To_Complete_Your_Talent:
          "Please make sure to complete your Talent profile to get a better rating and allow Companies that are publishing jobs find you easier!",
        if_You_Need_More_Info_About_Our_Pricing:
          "If you need more info about our pricing, please email us at",
        fill_The_Basic_Data: "Fill the Basic data",
        fill_the_Contact_Information: "Fill the contact information",
        clear_filter_button: "Clear filter",
        items_Found: "Items Found",
        by_Recently_Added: "by Recently Added ↓",
        SelectLocation: "Select Location",
        SelectSalaryRange: "Select Salary Range",
        SelectSEducationLevel: "Select Education Levels",
        training_Pie_De_Pagina: "Showing",
        training_Pie_De_Pagina2: "to",
        training_Pie_De_Pagina3: "of",
        training_Pie_De_Pagina4: "entries",
        recently_Updated: "Recently Added",
        last_Month: "Last Month",
        last_Quarter: "Last Quarter",
        last_Year: "Last Year",
        please_Check_Fields_msj: "Please check the red fields.",
        have_SomeErrors: "We have some errors.",
        company_Information_Been_Saved:
          "Your company information has been saved!",
        Upgrade_Subscription: "Upgrade Subscription",
        bill_to: "Bill to",
        customer_Name: "Customer Name",
        currency: "Currency",
        subscribed_Product: "Subscribed Product",
        billing_Method: "Billing method",
        phone: "Phone",
      },

      ajustes: {
        update_Password_button: "Update Password",
        reset_Password_button: "Reset Password",
        email_Address: "Email Address",
        confirm_Password: "Confirm Password",
        update_Email: "Update Email",
        sign_In_Method: "Sign-in Method",
        cancel: "Cancel",
        change_Email: "Change Email",
        password: "Password",
        current_Password: "Current Password",
        new_Password: "New Password",
        confirm_New_Password: "Confirm New Password",
        eliminate_Account: "Eliminate Account",
        confirm_Account_Elimination: "Confirm Account Elimination",
        enter_Email_Address: "enter Email Address",
        enter_New_Email_Address: "Enter New Email Address",
        confirm_New_Email_Address: "Confirm New Email Address",
        please_Wait: "Please wait...",
        Password_Must_Be_At_Least_8_Character:
          "Password must be at least 8 character and contain symbols",
        deseas_Eliminar_Tu_Cuenta: "You are Deleting Your Account",
        by_Deleting_Your_Account:
          "By deleting your account, all the data and content associated to your company profile will be lost.",
        for_Extra_Security_This_Requires:
          "For extra security, this requires you to confirm your email and password.",
      },

      employees: {
        add_Employees_button: "Add Employees",
        delete_button: "Delete",
        submit_button: "Submit",
        current_Employees: "Current Employees",
        instructions: "Instructions: ",
        first_Name: "First Name",
        last_Name: "Last Name",
        job_Title: "Job Title",
        password: "Password",
        email: "Email",
        confirm_Password: "Confirm Password",
        please_wait: "Please wait...",
        add_Company_Users: "Add Company Users",
        subscription_details: "Subscriptions Details",
        add_A_Company_User: "Add A Company User",
        company_Admin_Users: "Company Admin Users: ",
        company_Admin_User_You_Will:
          "Company Admin User, you will be able to edit your Company’s profile, add other Company Users, create Job Postings, and browse for Talent.",
        company_Users: "Company Users: ",
        allow_Other_Members_Of_Your_Company:
          "Allow other members of your company to join your Cybersecurity Talent search in Seccuri, by creating a profile for them and sharing the login credentials. These Company Users will be able to manage Job Postings and browse for Talent.",
        ask_Other_Members_Of_Your_Company:
          "Ask other members of your Company to become part of Seccuri! By including their emails in this section, we will be sending them an official invitation to join Seccuri and be part of your Company's journey in seeking Cybersecurity Talent. Once they register, they will also be able to manage your company's Seccuri profile, post jobs and review talent.",
        as_A_Company_Admin_User:
          "As a Company Admin User, you are responsible for creating and sharing other Company Users' passwords. Make sure to create a password for each Company User and internally share it with them. All fields are mandatory",
        are_you_sure: "Are you sure you want to remove this employee?",
        delete_employee: "Remove employee",
        delete: "Delete",
      },

      jobs: {
        create_new_pos_button: "Create new job position",
        save_As_button: "Save as ",
        change_Status_button: "Change Status",
        Status: "Status:",
        edit_Job_button: "Edit Job",
        show_Profile_button: "Show Profile",
        delete_button: "Delete",
        delete_Job: "Delete Job",
        back_button: "Back",
        jobs: "Jobs",
        position_Details: "Position Details",
        nist_Nice_Roles: "NIST NICE Roles",
        certifications: "Certifications",
        languages: "Languages",
        job_Title: "Job Title",
        company_Name: "Company Name",
        job_Department: "Job Department",
        job_Description: "Job Description",
        vacancy_Details: "Vacancy Details",
        salary_Range_Min: "Salary Range Min",
        salary_Range_Max: "Salary Range Max",
        select_Employment_Type: "Select Employment Type",
        duration: "Duration",
        requirements: "Requirements",
        industry: "Industry",
        level_Of_Education: "Level of Education",
        location: "Location",
        select_A_Country: "Select a Country...",
        nationatily: "Nationatily",
        expiration_Date: "Expiration Date",
        salary_Range: "Salary Range",
        top: "Top ",
        candidates: "Candidates",
        details: "Details",
        contact: "Contact",
        email: "Email",
        langauge: "Language",
        english: "English",
        change_Job_Status: "Change Job Status",
        published: "Published",
        delete_Job_Position: "Delete Job Position",
        only_required_Company: "Only if required by the Company",
        create_New_Job_Position: "Create new job position",
        basic_Information: "Basic Information",
        are_You_Ready_To_Start_Finding_Talent:
          "Are you ready to start finding Talent for your Company?",
        jobs_Subtitle2: "Showing 1 to 10 of 50 entries",
        edit_Job_Position: "Edit job position",
        fill_In_Your_Job_Data: "Fill in your Job data",
        specialities_And_Tasks: "Specialities and Tasks",
        list_Certifications_Required: "List certifications Required",
        fill_Native_And_Learned_Languages:
          "Fill native and learned languages required",
        please_Wait: "Please wait...",
        select_An_Option: "Select an Option...",
        units: "Units (Days, Months, etc.)",
        open_To_Travel: "Open to Travel",
        include_Job_Functions_And_Responsibilities:
          "(Include job functions and responsibilities)",
        these_Are_The: "These are the ",
        candidates_With_More_Match:
          "candidates with more match for this position.",
        the_Percentage_Displayed_For_Each_Talent:
          "The percentage displayed for each Talent indicates how much the candidate matches each of your job posting's roles!",
        the_Current_Status_For_This_Job_Position:
          "The current status for this job position is",
        are_You_Sure_You_Want_To_Change:
          ". Are you sure you want to change the status of this job position to",
        saved_as_Draft: "Saved as Draft",
        are_You_Sure_You_Want_To_Delete:
          "Are you sure you want to delete the job position?",
        tell_this_talent:
          "Tell this Talent why your Company is interested on contacting them for this job",
        use_the_text:
          "Use the text box below to reach out to this candidate. Send them a short message to initiate contact!",
        contact_to: "Contact",
        the_talent: "the Talent",
      },

      profile: {
        select_Industry_Placeholder: "Select an Industry...",
        select_Country_Placeholder: "Select a Country...",
        select_year_Placeholder: "Select a Year...",
        select_Option_Placeholder: "Select an Option...",
        basic_Information: "Basic Information",
        company_Name: "Company Name",
        industry: "Industry",
        location: "Location",
        description: "Description",
        ceo: "CEO",
        year_Of_Fundation: "Year of Fundation",
        company_Details: "Company Details",
        number_Of_Employees: "Number of Employees",
        revenue_Range: "Revenue Range",
        contact_Information: "Contact Information",
        admin_First_Name: "Admin First Name",
        admin_Last_Name: "Admin Last Name",
        admin_Job_Title: "Admin Job Title",
        phone_Number: "Phone Number",
        phone_Number_Example: "hone Number Example",
        website: "Website",
        secondary_Email: "Secondary Email",
        country_Code: "Country Code",
        this_Email_Will:
          "This email will allow you recover the main email in case that you forget it.",
        upload_Company_Logo: "Upload Company Logo",
        allowed_File_Types: "Allowed file types: png, jpg, jpeg.",
        select_An_Industry: "Select an Industry...",
        select_A_Country: "Select a Country...",
        select_An_Option: "Select an Option...",
        give_Us_A_Short_Description:
          "Give us a short description of the Company (mission statement, history, business structure, etc.)",
      },

      roles: {
        delete_button: "Delete",
        save_button: "Save",
        placeholder_Search_Tasks: "Search Tasks",
        nist_Nice_Roles: "NIST NICE Roles",
        options: "options.",
        next_Step: "Next Step",
        confirm_Changes: "Confirm changes",
        certifications: "Certifications",
        add_New: "Add New",
        certification: "Certification",
        name: "Name",
        languages: "Languages",
        add_New_Language: "Add New Language",
        please_Define_The_Roles_Associated:
          "Please define the roles associated to your job position!",
        in_This_Section_Which_Consists:
          "In this section, which consists of three steps, you will be selecting the job's NIST NICE roles.",
        please_start_By_Selecting_At_Least:
          "Please start by selecting at least one area of expertise that relates to this job position",
        by_Clicking_Here: "by clicking here",
        improve_Your_Roles: "Improve your roles!",
        you_can_Improve_The_Progress_Of_Your_Roles:
          "You can improve the progress of your roles at any moment by updating your areas, specialities and tasks by editing your roles",
        select_Your_Areas: "1. Select your Areas here",
        your_Nist_Nice_Roles: "Your NIST NICE Roles",
        the_Job_Position_Roles_And_Their_Progress:
          "The job position's roles and their progress are based on your selected specialities and tasks",
        edit_Your_Roles: "Edit your roles",
        select_Your_Specialities: "2. Select your Specialities",
        select_Your_Tasks: "3. Select your Tasks",
        please_Select_At_Least_One_Area: "Please select at least one area!",
        before_You_Proceed_With_Step:
          "Before you proceed with Step 2, please select at least one area of expertise...",
        please_Select_At_Least_One_Speciality:
          "Please select at least one speciality!",
        to_Continue_With_The_Next_Step:
          "To continue with the next step, please select at least one speciality for the job.",
        please_Select_At_Least_One_Task: "Please select at least one task!",
        to_Continue_With_The_Next_Step_Is_Important:
          "To continue with the next step is important that you select at least one task.",
        choose_Your_Areas: "Choose your areas",
        select_The_Main_Areas_That_Make:
          "Select the main areas that make up your job position. Choose up",
        choose_Your_Specialities: "Choose your specialities",
        select_The_Main_Specialities_Required:
          "Select the main specialities required for the job position. Choose up to",
        choose_Your_Tasks: "Choose your tasks",
        select_All_Of_The_Tasks_That_Will_Need:
          "Select all of the tasks that will need to be performed for the job.",
        show_Only_Selected: "Show only selected",
        provide_Information_About_Your_Certifications:
          "Provide information about your certifications.",
        provide_Information_About_The_Languages:
          "Provide information about the languages required for the job.",
      },

      language: {
        delete_button: "Delete",
        save_button: "Save",
        language: "Language",
        begginer: "Begginer",
        intermediate: "Intermediate",
        proficient: "Proficient",
        native_Biligual: "Native/Biligual",
        select_An_Option: "Select an Option...",
        select_A_Level: "Select a level",
        knowledge_Of_Basic_Vocabulary_Words:
          "Knowledge of basic vocabulary words, speaks simple sentences, elementary reading and writing skills.",
        can_Handle_Intermediate_Communication:
          "Can handle intermediate communication, reading and writing.",
        a_Well_Advanced_Use_Of_The_Language:
          "A well advanced use of the language, using fluid speech, reading and writing.",
        more_Than_Fluent_In_The_Language: "More than fluent in the language.",
      },

      searchTalent: {
        find_The_Best_Cybersecurity_Talent:
          "Find the best cybersecurity talent your company needs",
      },
    },

    loginView: {
      index: {
        continue_button: "Continue",
        register_button: "Register",
        email: "Email",
        password: "Password",
        forgot_Password: "Forgot Password ?",
        company_Account: "Company Account",
        talent_Account: "Talent Account",
        first_Name: "First Name",
        last_Name: "Last Name",
        admin_First_Name: "Admin First Name",
        admin_Last_Name: "Admin Last Name",
        admin_Email: "Admin Email",
        phone: "Phone",
        country: "Country",
        confirm_Password: "Confirm Password",
        login_Tittle1: "Sign In to Seccuri",
        login_Tittle2: "Choose Account Type",
        login_Titlle3: "Create a Talent Account",
        login_Tittle4: "Create a Company Account",
        login_Subtittle1: "New Here?",
        login_subtittle2: "Create an Account",
        login_Subtittle3: "Please wait...",
        login_subtittle4: "Looking for a job",
        login_Subtittle5: "Seeking training opportunities",
        login_Subtittle6: "Looking to hire Talent",
        login_Subtittle7: "Seeking training opportunities for employees",
        login_Subtittle8: "Already have an account?",
        login_Subtittle9: "Sign in here",
        login_Subtittle10: "Select a Country...",
        login_subtittle11:
          "Use 12 or more characters with a mix of letters (lower and upper), numbers & symbols.",
        login_Subtittle12: "I Agree to the",
        login_Subtittle13: "Terms and Conditions.",
      },

      forgotPassword: {
        reset_Password_button: "Reset Password",
        cancel_button: "Cancel",
        continue_button: "Continue",
        forgotPassword: "Forgot Password",
        email: "Email",
        OldEmail: "Old Email",
        newEmail: " New Email",
        please_Wait: "Please wait...",
        reset_Your_Password: "Reset your password",
        reset_Your_Email: "Change your Email",
        token: "Token",
        password: "Password",
        confirm_Password: "Confirm Password",
        forgot_Subtitle1: "Enter your email to reset your password.",
        forgot_Subtitle2:
          "Use 12 or more characters with a mix of letters, numbers & symbols.",
        forgot_Subtitle3:
          "Enter and confirm your new password with the temporal code received by email.",
        forgot_Subtitle4:
          "Enter and confirm your new Email with the temporal code received by email.",
      },
    },

    profileView: {
      index: {
        next_Step_butoon: "Next Step",
        confirm_Changes_button: "Confirm changes",
        back_button: "Back",
        submit_button: "Submit",
        save_Continue_button: "Save and Continue",
        continue_button: "Continue",
        go_Education_button: "Go to Education",
        go_Experiences_button: "Go to Experiences",
        go_Certifications_button: "Go to Certifications",
        go_Courses_button: "Go to Courses",
        go_Languages_button: "Go to Languages",
        go_References_button: "Go to References",
        next_Section_button: "Next Section",
        search_Tasks_Placeholder: "Search Tasks",
        edit_Profile: "Edit Profile",
        personal_Details: "Personal Details",
        nist_Nice_Roles: "NIST NICE Roles",
        experience: "Experience",
        education: "Education",
        certifications: "Certifications",
        languages: "Languages",
        references: "References",
        courses: "Courses",
        basic_Information: "Basic Information",
        first_Name: "First Name",
        middle_Name: "Middle Name",
        when_was_it_last: "When was it last granted?",
        security_clearance_renewal: "Security Clearance Renewal",
        last_Name: "Last Name",
        gender: "Gender",
        nationality: "Nationality",
        location: "Location",
        contact_Information: "Contact Information",
        phone_Number: "Phone Number",
        website: "Website",
        birthDate: "Birth Date",
        secondary_Email: "Secondary Email",
        this_Email_Will:
          "This email will allow you recover the main email in case that you forget it.",
        education_Information: "Current Information",
        school_Name: "School Name",
        degree_Title: "Lastest Degree Title",
        professional_Information: "New Opportinoties",
        please_Take_this_Test:
          "Take your personality test and fill the required information below.",
        personality: "Personality",
        select_personalityType: "Select Personality Result",
        have_Personality_Test: "Have you already taken your personality test?",
        personality_Test_result: "Personality test result",
        personality_Test_Up: "Take your personality test!",
        current_Job_Title: "Current Job Title",
        desired_Employment_Type: "Desired Employment Type",
        company_Name: "Company Name",
        select_your_Areas: "1. Select your Areas here",
        select_your_Specialities: "2. Select your Specialities",
        select_your_Tasks: "3. Select your Tasks",
        options: "options.",
        please_Wait: "Please wait...",
        upload_a_Photo: "Upload a Photo",
        profile_Tittle2: "Please select at least one area!",
        profile_Subtittle1: "Fill in your personal data",
        profile_Subtittle2: "Specialities and Tasks",
        profile_Subtittle3: "Fill in your employment history",
        profile_Subtittle4: "List your education history",
        profile_Subtittle5: "List your certifications",
        profile_Subtittle6: "List your native and learned languages",
        profile_Subtittle7: "List your external references",
        profile_Subtittle8: "List your history of courses",
        allowed_File_Types: "Allowed file types: png, jpg, jpeg.",
        gender_Profile: "Select a Gender...",
        select_security_level: "Select a security Level...",
        select_a_Country: "Select a Country...",
        profile_Subtittle12: "Select Highest Level of Education",
        profile_Subtittle13: "Select an Option...",
        profile_Subtittle14: "Open to Travel",
        profile_Subtittle15: "Currently Seeking Opportunities?",
        profile_Subtittle16:
          "To continue with the next step is important that you select at least one area.",
        profile_Subtittle17: "Choose your areas",
        profile_Subtittle18:
          "Select the main areas that you have worked in. Choose up to",
        profile_subtittle19: "options.",
        profile_Subtitle20: "Please select at least one speciality!",
        profile_Subtitle21:
          "To continue with the next step is important that you select at least one speciality.",
        profile_Subtitle22: "Please select at least one task!",
        profile_Subtitle23:
          "To continue with the next step is important that you select at least one task.",
        profile_Subtitle24: "Choose your specialities",
        profile_Subtitle25:
          "Select the main specialities that you have worked in. Choose up to",
        profile_Subtitle26: "Choose your tasks",
        profile_Subtitle27: "Select all the tasks you have performed.",
        profile_Subtitle28: "Show only selected",
        new_in_cybersecurity: "I am new to Cybersecurity",
        hi_new_talent_cybersecurity: "Hello, cybersecurity enthusiast!",
        hi_talent_cybersecurity: "Welcome back, Cybersecurity Talent!",
        experience_in_cybersecurity: "I have experience in cybersecurity",
        in_trainings:
          "We invite you to browse our Training section, where you can immerse yourself in different topics through courses and certifications, which will allow you to start your career in cybersecurity.",
        at_the_moment:
          "Once you have obtained some experience in cybersecurity, go to the",
        para_que:
          "option so that we can assess your current skills according to the NIST NICE framework. This will allow us to track your progress and ensure that your profile is more complete when applying for new job opportunities (if desired).",
        select_begginer:
          "By selecting this option, we will share with you all the information about the courses and trainings you can take to start your professional career in cybersecurity.",
        nist_nice: "NIST NICE Framework",
        here: "here.",
        start_your_career: "Start your Career in Cybersecurity",
        confirm: "Confirm Selection",
        nist_nice_framework:
          "At Seccuri we use different mechanisms to evaluate your cybersecurity career; one of them is through the NIST NICE framework. If you want to learn more about this method click",
        your_nist_nice:
          "To know and modify your NIST NICE roles select the option",
        seccuri_career_path: "Seccuri Career Paths",
        seccuri_career_Path_this_module:
          "This module has been developed based on marketing analysis by the Seccuri team to offer the cybersecurity careers that are available on the market.",
        seccuri_career_Path_each_career:
          "Each Career Path provides the detailed progress, knowledge and trainings you need to continue to improve your level.",
        seccuri_career_Path_being_updated:
          "This module is constantly being updated. We will add more career paths, trainings and features.",
        have_Security_Clearance: "I have Security Clearance",
        security_Clearance_required: "Security Clearance Required",
        polygraph_Performed: "Polygraph Performed",
        security_level: "Security Level",
        career_Progress: "Career Progress ",
        view_Button: "View",
        description: "Description",
        current_Career_Path_Progress: "Your Current Career Path Progress",
        entry_Level: "Entry Level",
        mid_Career: "Mid-Career",
        Professional: "Professional",
        for: "For",
        career_Path_Current_Level: " Career path, your current level is ",
        progress_Bar_Above_Serves_Tool:
          "The progress bar above serves as a tool to help you track your advances in the selected cybersecurity career path. The initial calculation comes from the NIST NICE analysis carried out in your Profile section, based on your current KSAs (Knowledge, Skills, Abilities) and preformed Tasks.",
        if_You_Have_Experience_Cybersecurity:
          "If you have experience in Cybersecurity, and have not yet completed the NIST NICE analysis, please head back to the Edit Profile section. In case you are new to Cyber (Cybersecurity Enthusiast), feel free to start some of the trainings listed below!",
        continue_Growing_Every_Time_Complete_Training:
          "This bar will continue growing every time you complete a training that certifies you on new required skills to advance in your selected career path.",
        browse_Suggested_Trainings_Below:
          "Browse the suggested trainings below to start upskilling and become one of the best Cybersecurity Experts in your field!.",
        trainings_For_This_Career: "Trainings for this career",
        back_All_Career_Paths: "Back to All Career Paths",
        view_All_Trainings: "View All Trainings",
        career_Path: "Career Path",
      },
      personalities: {
        Inspector:
          "At first glance, ISTJs are intimidating. They appear serious, formal, and proper. They also love traditions and old-school values that uphold patience, hard work, honor, and social and cultural responsibility. They are reserved, calm, quiet, and upright. These traits result from the combination of I, S, T, and J, a personality type that is often misunderstood.",
        Counselor:
          "INFJs are visionaries and idealists who ooze creative imagination and brilliant ideas. They have a different, and usually more profound, way of looking at the world. They have a substance and depth in the way they think, never taking anything at surface level or accepting things the way they are. Others may sometimes perceive them as weird or amusing because of their different outlook on life.",
        Mastermind:
          "INTJs, as introverts, are quiet, reserved, and comfortable being alone. They are usually self-sufficient and would rather work alone than in a group. Socializing drains an introvert's energy, causing them to need to recharge. INTJs are interested in ideas and theories. When observing the world they are always questioning why things happen the way they do. They excel at developing plans and strategies, and don't like uncertainty.",
        Giver:
          "ENFJs are people-focused individuals. They are extroverted, idealistic, charismatic, outspoken, highly principled and ethical, and usually know how to connect with others no matter their background or personality. Mainly relying on intuition and feelings, they tend to live in their imagination rather than in the real world. Instead of focusing on living in the “now” and what is currently happening, ENFJs tend to concentrate on the abstract and what could possibly happen in the future.",
        Craftsman:
          "ISTPs are mysterious people who are usually very rational and logical, but also quite spontaneous and enthusiastic. Their personality traits are less easily recognizable than those of other types, and even people who know them well can't always anticipate their reactions. Deep down, ISTPs are spontaneous, unpredictable individuals, but they hide those traits from the outside world, often very successfully.",
        Provider:
          "ESFJs are the stereotypical extroverts. They are social butterflies, and their need to interact with others and make people happy usually ends up making them popular. The ESFJ usually tends to be the cheerleader or sports hero in high school and college. Later on in life, they continue to revel in the spotlight, and are primarily focused on organizing social events for their families, friends and communities. ESFJ is a common personality type and one that is liked by many people.",
        Idealist:
          "INFPs, like most introverts, are quiet and reserved. They prefer not to talk about themselves, especially in the first encounter with a new person. They like spending time alone in quiet places where they can make sense of what is happening around them. They love analyzing signs and symbols, and consider them to be metaphors that have deeper meanings related to life. They are lost in their imagination and daydreams, always drowned in the depth of their thoughts, fantasies, and ideas.",
        Performer:
          "ESFPs have an Extraverted, Observant, Feeling and Perceiving personality, and are commonly seen as Entertainers. Born to be in front of others and to capture the stage, ESFPs love the spotlight. ESFPs are thoughtful explorers who love learning and sharing what they learn with others. ESFPs are “people people” with strong interpersonal skills. They are lively and fun, and enjoy being the center of attention. They are warm, generous, and friendly, sympathetic and concerned for other people's well-being.",
        Champion:
          "ENFPs have an Extraverted, Intuitive, Feeling and Perceiving personality. This personality type is highly individualistic and Champions strive toward creating their own methods, looks, actions, habits, and ideas — they do not like cookie cutter people and hate when they are forced to live inside a box. They like to be around other people and have a strong intuitive nature when it comes to themselves and others. They operate from their feelings most of the time, and they are highly perceptive and thoughtful.",
        Doer: "ESTPs have an Extraverted, Sensing, Thinking, and Perceptive personality. ESTPs are governed by the need for social interaction, feelings and emotions, logical processes and reasoning, along with a need for freedom. Theory and abstracts don't keep ESTP's interested for long. ESTPs leap before they look, fixing their mistakes as they go, rather than sitting idle or preparing contingency plans.",
        Supervisor:
          "ESTJs are organized, honest, dedicated, dignified, traditional, and are great believers of doing what they believe is right and socially acceptable. Though the paths towards “good” and “right” are difficult, they are glad to take their place as the leaders of the pack. They are the epitome of good citizenry. People look to ESTJs for guidance and counsel, and ESTJs are always happy that they are approached for help.",
        Commander:
          "An ENTJ's primary mode of living focuses on external aspects and all things are dealt with rationally and logically. Their secondary mode of operation is internal, where intuition and reasoning take effect. ENTJs are natural born leaders among the 16 personality types and like being in charge. They live in a world of possibilities and they often see challenges and obstacles as great opportunities to push themselves. They seem to have a natural gift for leadership, making decisions, and considering options and ideas quickly yet carefully. They are “take charge” people who do not like to sit still.",
        Thinker:
          "INTPs are well known for their brilliant theories and unrelenting logic, which makes sense since they are arguably the most logical minded of all the personality types. They love patterns, have a keen eye for picking up on discrepancies, and a good ability to read people, making it a bad idea to lie to an INTP. People of this personality type aren't interested in practical, day-to-day activities and maintenance, but when they find an environment where their creative genius and potential can be expressed, there is no limit to the time and energy INTPs will expend in developing an insightful and unbiased solution.",
        Nurturer:
          "ISFJs are philanthropists and they are always ready to give back and return generosity with even more generosity. The people and things they believe in will be upheld and supported with enthusiasm and unselfishness. ISFJs are warm and kind-hearted. They value harmony and cooperation, and are likely to be very sensitive to other people's feelings. People value the ISFJ for their consideration and awareness, and their ability to bring out the best in others.",
        Visionary:
          "Those with the ENTP personality are some of the rarest in the world, which is completely understandable. Although they are extroverts, they don't enjoy small talk and may not thrive in many social situations, especially those that involve people who are too different from the ENTP. ENTPs are intelligent and knowledgeable need to be constantly mentally stimulated. They have the ability to discuss theories and facts in extensive detail. They are logical, rational, and objective in their approach to information and arguments.",
        Composer:
          "ISFPs are introverts that do not seem like introverts. It is because even if they have difficulties connecting to other people at first, they become warm, approachable, and friendly eventually. They are fun to be with and very spontaneous, which makes them the perfect friend to tag along in whatever activity, regardless if planned or unplanned. ISFPs want to live their life to the fullest and embrace the present, so they make sure they are always out to explore new things and discover new experiences. It is in experience that they find wisdom, so they do see more value in meeting new people than other introverts.",
      },

      workExperince: {
        add_Job_button: "Add New Job Experience",
        delete_button: "Delete",
        save_button: "Save",
        employment_History: "Employment History",
        employment: "Employment",
        job_Title: "Job Title",
        company: "Company",
        start_Date: "Start Date",
        end_Date: "End Date",
        employment_Type: "Employment Type",
        industry: "Industry",
        job_Description: "Job Description",
        experience_Subtitle1:
          "Tell us about your professional experience, starting with your latest recent work.",
        experience_Subtitle2: "Currently work here?",
        experience_Subtitle3: "Select an Option...",
        experience_Subtitle4: "About the Company",
        experience_Subtitle5:
          "(Include industry, location, mission statement, etc...)",
        experience_Subtitle6:
          "(Include job functions and responsibilities, work experience, accomplishments, etc...)",
      },

      education: {
        add_New_Education_button: "Add New Education",
        delete_button: "Delete",
        save_button: "Save",
        select_date_Placeholder: "Select date",
        education_Placeholder2:
          "(Write a summary of the groups and activities you were involved with while studying.)",
        education: "Education",
        education1: "Education",
        school: "School",
        degree: "Degree",
        field_Of_Study: "Field of Study",
        graduated: "Graduated",
        graduation_Date: "Graduation Date",
        groups_Activities: "Groups / Activities",
        education_Subtitle1:
          "A varied education on your resume sums up the value that your learnings and background will bring to a job.",
      },

      certifications: {
        add_New_Certification_button: "Add New Certification",
        delete_button: "Delete",
        save_button: "Save",
        select_month_Placeholder: "Select a month",
        certifications: "Certifications",
        certification: "Certification",
        name: "Name",
        issuing_Entity: "Issuing Entity",
        date_Issued: "Date Issued",
        date_Expired: "Date Expired",
        certifications_Subtitle1:
          "Provide information about the certifications achieved.",
        certification_Subtitle2: "This certification expires",
      },

      courses: {
        add_New_Course_button: "Add New Course",
        delete_button: "Delete",
        save_button: "Save",
        courses: "Courses",
        course: "Course",
        name: "Name",
        number: "Number",
        associated_With: "Associated with",
        courses_Subtitle1: "Provide information about the courses taken.",
      },

      languages: {
        add_New_Language_button: "Add New Language",
        delete_button: "Delete",
        save_button: "Save",
        select_Language_Placeholder: "Select a language...",
        languages: "Languages",
        language: "Language",
        select_level: "Select a level",
        begginer: "Begginer",
        intermediate: "Intermediate",
        proficient: "Proficient",
        native_Biligual: "Native/Biligual",
        languages_Subtitle1: "Provide information about your languages.",
        language_Subtitle2: "Select an Option...",
        language_Subtitle3:
          "Knowledge of basic vocabulary words, speaks simple sentences, elementary reading and writing skills.",
        language_Subtitle4:
          "Can handle intermediate communication, reading and writing.",
        language_Subtitle5:
          "A well advanced use of the language, using fluid speech, reading and writing.",
        fluent_in_the_Language: "You are fluent in the language.",
      },

      references: {
        add_New_button: "Add New",
        delete_button: "Delete",
        save_button: "Save",
        references: "References",
        reference: "Reference",
        email: "Email",
        references_Subtitle1: "Provide information about your references.",
      },
    },

    alertsView: {
      alerts: {
        form_Successfully_Submitted: "Form has been successfully submitted!",
        ok_Got_It_button: "Ok, got it!",
        try_again_button: "Try again!",
        Ok_button: "Ok!",
        reset_Password_button: "Reset password!",
        sorry_Looks_Like:
          "Sorry, looks like there are some errors detected, please try again.",
        customer_Required: "Customer name is required",
        customer_Email_Required: "Customer email is required",
        address_Required: "Address 1 is required",
        town_Required: "Town is required",
        state_Required: "State is required",
        post_Code_required: "Post code is required",
        last_Week: "Last week",
        last_month: "Last month",
        last_Three_months: "Last 3 months",
        date_Range_Required: "Date range is required",
        new_Been_Added: "Your new user has been added",
        error_Adding_User: "Error adding user.",
        have_Some_Problems:
          "we have some problems with apply job or send de message to the company",
        hooray_Succesfully_Applied:
          "Hooray! You have succesfully applied to this job. The Company will be reviewing your profile soon and contacting you if interested.",
        Status_Job_Posting: "The status of this job posting has been changed.",
        error_Changing_Status: "We have an error changing status.",
        succesfullyCompleted_Training:
          "Hooray! You have succesfully completed this training",
        problem_Apply_Job:
          "we have some problems with apply job or send de message to the company",
        sending_Mail_Information:
          "We will be sending you an email with information on how to obtain apply our promo code soon.",
        employee_Been_Deleted: "The employee has been deleted.",
        error_Deleting_Employee: "We have an error deleting the employee.",
        position_Deleted: "The position has been deleted.",
        error_Deleting_Position: "We have an error deleting the position.",
        now_Submit_Form: "All is cool! Now you submit this form",
        your_Job_Information:
          "Your Job information has been saved! Our specialized AI Algorithm is ready to match your job with our Talent pool!",
        profile_Information_Saved: "Your profile information has been saved!",
        check_Red_Fields: "Please check the red fields.",
        securityClearanceRequired:
          "This Job have security Clearance required. Only the people that configured Security Clearance on your profile information can be apply.",
        sorry: "Sorry",
        by_acepting_you:
          "By accepting you have security clearance (or have been granted one in the past) you are consenting to giving truthful information that might be needed for further background investigation and candidate-validation processes.",
        in_case_you_currently:
          "In case you currently have an “active” or “current” clearance status and are applying to a job position that requires a determined clearance level, you may still be subject to background revisions on behalf of a company's Human Resource Department or by the Federal Government.",
        form_Several_Errors:
          "The form have several errors, please check the red fields.",
        course_Deleted: "The course has been deleted.",
        error_Deleting_Course: "We have an error deleting the course.",
        courses_Updated_Successfully:
          "Your courses have been updated successfully.",
        certification_Deleted: "The certification has been deleted.",
        error_Deleting_Certification:
          "We have an error deleting the certification.",
        certifications_Updated_Successfully:
          "Your certifications have been updated successfully.",
        dateIssueIsRequired: "Date.",
        education_Deleted: "The education has been deleted.",
        error_deleting_Education: "We have an error deleting the education.",
        education_History_Updated_Successfully:
          "Your education history has been updated successfully.",
        language_Deleted: "The language has been deleted.",
        error_Deleting_Language: "We have an error deleting the language.",
        languages_Updated_Successfully:
          "Your languages have been updated successfully.",
        reference_Deleted: "The reference has been deleted.",
        error_deleting_Reference: "We have an error deleting the reference.",
        references_Updated_Successfully:
          "Your references have been updated successfully.",
        information_Succesfully_Submitted:
          "your information has been succesfully submitted!",
        invalid_Infromation: "invalid infromation.",
        roles_Updated_Successfully:
          "Your roles have been updated successfully.",
        message_Already_Talent: "The message was already sent to the talent.",
        problems_Sending_Message_Talent:
          "we have some problems sending the message to the talent.",
        account_Eliminated: "Your account has been eliminated.",
        email_Password_Invalid: "Email or password invalid.",
        email_Been_reset:
          "Your email has been reset. Please use the new email to login.",
        password_Been_Reset:
          "Your password has been reset. Please use the new email to login.",
        password_Confirmation_Password_Invalid:
          "New password or confirmation password invalid.",
        email_Successfully_Changed: "Email is successfully changed!",
        password_Successfully_Changed: "Password is successfully changed!",
        account_Already_Activated: "Your account has been already activated.",
        token_Invalid: "Token invalid.",
        instructions_Reset_Password:
          "You will receive an email with the instructions to reset your password",
        user_Password_Invalid: "User or password invalid.",
        password_Must_More_Characters:
          "Password must be at least 12 or more characters with a mix of letters, numbers & symbols.",
        password_Changed_Succesfully:
          "Your password has been changed succesfully. Please use the new password to login.",
        email_Changed_Succesfully:
          "Your email has been changed succesfully. Please use the new email to login.",
        maximun_Number_Account_Blocked:
          "You have reached the maximun number of login attempts and your account is blocked. To begin using Seccuri's platform, you will need to reset your password",
        fill_Valid_Phone_Number: "Please fill a valid phone number.",
        password_More_Characters:
          "Password must be at least 12 or more characters with a mix of letters(lower and upper), numbers & symbols.",
        accept_Termns_Conditions:
          "Please read and accept the termns of conditions.",
        successfully_Registered_Seccuri:
          "Congratulations! You have successfully registered to Seccuri. Please check your inbox to verify and activate your account.",
        happened_Error_During_Register:
          "Sorry! Happened an error during the register, please try again.",
        exit_edit_profile:
          "Wait! If you close your edit profile now, you could lose your profile information. Please make sure to save your profile before leaving. Are you sure you want to Exit?",
        confirm_exit_edit_profile: "Exit",
        Open_edit_profile_Hi: "Hi ",
        Open_edit_profile:
          "You are about to start editing your profile. Please make sure to save your information as you enter it using the blue “save” buttons for each section, to avoid losing it at the end of the process.",
        password_Expired:
          "For security reasons your password has expired. For continue using Seccuri's Platform you must change it to following the instructions",
        password_Expired_Alert:
          "For security reasons your password will expire in",
        password_Expired_Alert_2:
          "days. Please change your account password as soon as possible for continue using Seccuri's Platform. ",
        temporary_Emails:
          "Seccuri's Platform does not support temporary email addresses.",
        please_Wait: "Please wait...",
        submit: "Submit",
        we_have_errors:
          "We have errors loading the selected career path. Please try again.",
      },
    },
  },
  es: {
    jobsView: {
      index: {
        create_new_pos_button: "Crear Nueva Vacante",
        edit_job_position_button: "Editar puesto de trabajo",
        apply_button: "Aplicar",
        go_Certifications_button: "Ir a las Certificaciones",
        go_Languages_button: "Ir a los Idiomas",
        continue_button: "Continuar",
        ok_Got_It_button: "¡Hecho!",
        try_Again_button: "¡Intentar de Nuevo!",
        go_Nist_Nice_button: "Ir a los Roles NIST NICE",
        personal_information: "Información personal",
        jobs: "Empleos",
        expiration_Date: "Fecha de Vencimiento",
        salary_Range: "Rango Salarial",
        view_Job: "Información de la Vacante",
        please_Wait: "Espere por favor...",
        Required_Company:
          "En caso de ser requerido por la empresa contratante.",
        experience_Deleted_msj: "La experiencia ha sido eliminada.",
        deleting_Experience_msj: "Tenemos un error al eliminar la experiencia.",
        your_Experiences_Have_msj:
          "Tus experiencias se han actualizado con éxito.",
        certification_Been_Deleted_msj: "La certificación ha sido eliminada.",
        have_Deleting_Certification_msj:
          "Tenemos un error al eliminar la certificación.",
        have_SomeErrors: "Estamos presentando algunos errores.",
        certification_Been_Updated_Successfully:
          "Tu certificación se ha actualizado con éxito.",
        please_Check_Red_Fields: "Por favor revisa los campos rojos.",
        roles_Succesfully_Updated:
          "¡Tus roles NIST NICE se han actualizado con éxito!",
        invalid_Infromation: "información no válida",
        full_Time: "Tiempo Completo",
        part_Time: "Medio Tiempo",
        self_Employed: "Trabajo por Cuenta Propia",
        freelance: "Tiempo Libre / Trabajo Independiente",
        contract: "Trabajo por Contrato",
        internship: "Internado",
        apprenticeship: "Aprendizaje",
        seasonal: "Temporal",
        not_Specified: "No especificado",
        less_Than_High_School: "Menos que el Colegio",
        high_School_Diploma_Equivalent:
          "Diploma de Escuela Secundaria o su Equivalente",
        oome_College_Degree: "Un Poco de Universidad, sin Título",
        postsecondary_Degree_Award: "Premio Postsecundario sin Título",
        associate_Degree: "Título de Asociado",
        bachelor_Degree: "Licenciatura",
        master_Degree: "Maestría",
        doctoral_Professional_Degree: "Doctorado o Título Profesional",
        //clear_filter_button: "Limpiar Filtro",
        salary_range:
          "El valor ingresado en el Rango máximo de salario debe ser mayor que el rango mínimo.",
      },

      language: {
        ok_Got_It_button: "¡Hecho!",
        try_Again_button: "¡Intentar de Nuevo!",
        language: "Idioma",
        select_Level: "Selecciona un nivel",
        begginer: "Principiante",
        knowledgeBasic:
          "Conoces palabras básicas, hablas oraciones simples, y tienes algunas habilidades de lectura y escritura.",
        intermediate: "Intermedio",
        handle_Intermediate:
          "Manejas una comunicación, lectura y escritura intermedia.",
        proficient: "Competente",
        well_Advanced:
          "Tienes un manejo avanzado del idioma, con fluidez en el habla, la lectura y la escritura.",
        native_Biligual: "Nativo/Bilingüe",
        more_fluent: "Eres más que fluido en el idioma.",
        language_Been_Deleted: "El idioma ha sido eliminado.",
        error_deleting_language: "Tenemos un error al eliminar el idioma.",
        languages_Succesfully_Updated:
          "Tus idiomas se han actualizado con éxito",
      },
    },

    plans: {
      ViewTopTalent: "Ver lo mejores talentos",
    },

    trainingView: {
      index: {
        Status: "Estado:",
        clear_filter_button: "Limpiar Filtros",
        search_placeholder: "Buscador",
        TRAINING: "ENTRENAMIENTO",
        CERTIFICATION: "CERTIFICACIÓN",
        learn_New_Skills_Through:
          "¡Descubre nuevos retos de entrenamiento en ciberseguridad para tu equipo!",
        pricing: "Precio",
        not_Started: "Sin Empezar",
        free: "Gratis",
        paid: "Con Cobro",
        done: "Terminado",
        all_Careers: "Todas las Carreras",
        training_Provider: "Proveedor",
        level: "Level",
        all_Roles: "Todos los Roles",
        categories: "Categorías",
        in_Progress: "En Progreso",
        certification: "Certificación",
        training: "Capacitación",
        search: "Buscar",
        items_Found: "Entrenamientos encontrados",
        by_Recently_Added: "Agregados Recientemente ↓",
        recently_Updated: "Agregado Recientemente",
        last_Month: "El mes pasado",
        last_Quarter: "El trimestre pasado",
        last_Year: "El año pasado",
        showing: "Mostrando",
        to: "a",
        of: "de",
        entries: "entradas",
        by: "Por:",
        author: "Autor:",
        duration: "Duración:",
        modules: "Módulos",
        regular_Price: "Precio en el Mercado:",
        usd: "USD",
        seccuri_Price: "Precio con Seccuri",
        contact_Us: "Contáctate con nosotros",
        target_Of_Training: "Detalles de la capacitación",
        required_For: "Habilidades Asociadas",
        contact_Seccuri_Team:
          "Ponte en contacto con nuestro equipo en Seccuri!",
        special_Prices: "nuestros precios especiales",
        complete_Training: "Capacitación Completa",
        as_Completed: "capacitación",
        contact_For_Special_Seccuri_Price:
          "¡Contáctenos para un precio especial en esta capacitación!",
        take_This_Training: "Toma esta Capacitación",
        back_To_Trainings: "Volver a los entrenamientos",
        check_Training_As_Completed: "Verifique la capacitación completa",
        are_You_Interested_On_Taking_This_Training:
          "¿Está interesado en tomar esta capacitación de Seccuri y obtener su acceso?",
        by_Submitting_This_Request:
          "Al enviar esta solicitud, le enviaremos un correo electrónico con información sobre cómo obtener y aplicar nuestro código de promoción durante el proceso de compra de esta capacitación.",
        contact_To_Take_This_Training: "Contacto para tomar esta Capacitación",
        please_Wait: "Espere por favor...",
        are_You_Sure_You_Want_To_Mark: "Seguro que quieres comprobar el",
        by_Submitting_This_Request_We_Will_Send:
          "Al enviar esta solicitud, le enviaremos un correo electrónico con información sobre cómo validaremos la capacitación completa.",
        SelectProvider: "Selecciona un Proveedor",
        SelectLevel: "Seleccione un Nivel",
        SelectLocation: "Selecciona una Ubicación",
        are_You_Interested_On_Taking_This_subscription:
          "¿Estás interesado en subscribirte al",
        are_You_Interested_On_Taking_This_subscription_2: " y tener acceso a",
        by_Submitting_This_Request_We_Will_Send_subscription:
          "Al enviar esta solicitud, le enviaremos un correo electrónico con información sobre cómo validaremos el proceso de compra.",
        contact_To_Take_This_subscription:
          "Contáctanos para conocer sobre este servicio",
        by_Submitting_This_Request_subscription:
          "Al enviar esta solicitud, le enviaremos un correo electrónico con información sobre cómo obtener y aplicar nuestro código promocional durante el proceso de compra de esta Suscripción.",
      },
    },

    talentView: {
      index: {
        edit_Profile_button: "Editar Perfil",
        next_Step_button: "Siguiente Paso",
        career: "Carrera Profesional",
        profile_Details: "Detalles del Perfil",
        roles: "Roles",
        education: "Educación",
        certification: "Certificación",
        courses: "Cursos",
        recommendations: "Recomendaciones",
        personal_Information: "Información Personal",
        personal_Data: "Datos Personales",
        nIST_NICE: "NIST NICE",
        experience: "Experiencia",
        masters: "Posgrados",
        certifications: "Certificaciones",
        courses_Finished: "Cursos Terminados",
        external_Recommendations: "Recomendaciones Externas",
        area: "Provisión segura",
        overview: "Información General",
        account: "Cuenta",
        is_Begginer: "Nuevo",
        is_BegginerDescription: "Talento de cyberseguridad",
      },
      roles: {
        edit_Roles_button: "Editar Roles",
        select_Areas: "1. Selecciona tus Áreas para empezar.",
        improve_Your_Roles: "¡Mejora tus Roles!",
        can_Improve_The_Progress:
          "Puedes mejorar el progreso de tus roles en cualquier momento actualizando tus áreas, especialidades y tareas. Haz click en",
        your_Nist_Nice_Roles: "Roles NIST NICE",
        your_Roles_And_Their_Progress:
          "Tus roles y su progreso se basan en las especialidades y tareas seleccionadas.",
      },

      job: {
        back_button: "Atrás",
        submit_button: "Enviar",
        job_Placeholder1:
          "Cuéntale a la empresa por qué estás interesado en postularte a esta vacante aquí.",
        position_Details: "Detalles de la Vacante",
        basic_Information: "Información básica",
        nist_Nice_Roles: "Roles NIST NICE",
        specialities_Tasks: "Especialidades y Tareas",
        certifications: "Certificaciones",
        certifications_Required: "Certificaciones Requeridas",
        languages: "Idiomas",
        job_Title: "Título del empleo",
        company_Name: "Nombre de la Empresa",
        job_Description: "Descripción del Empleo",
        job_Department: "Departamento del Empleo",
        vacancy_Details: "Detalles de la Vacante",
        salary_Range_Min: "Rango Mínimo de Salario",
        salary_Range_Max: "Rango Máximo de Salario",
        employment_Type: "Descripción del Empleo",
        duration: "Duración",
        requirements: "Requerimientos",
        industry: "Industria",
        level_Education: "Nivel de Educación",
        open_Travel: "Disponibilidad Para Viajar",
        location: "Ubicación",
        nationatily: "Nacionalidad",
        contact_Company: "Ponte en Contacto con la Empresa!",
        certification: "Certificación",
        name: "Nombre",
        view_Position: "Ver Vacante",
        native_And_Learned_Languages_Required:
          "Idiomas nativos y aprendidos requeridos",
        units: "Unidades (Días, Meses, etc.)",
        this_Job_Does_Not_Require_Certifications:
          "Este trabajo no requiere ninguna certificación.",
        please_Continue_Browsing_Through:
          "Continúe navegando a través de la siguiente sección.",
        nist_Nice_Roles_Required: "Roles requeridos de NIST NICE",
        minimum_Percentage_Of_Nist_Nice_Roles:
          "Porcentajes mínimos de los roles NIST NICE requeridos para postularse a este puesto de trabajo",
        this_Position_Has_No_Specified:
          "¡Este puesto aún no tiene certificaciones especificadas!",
        this_Section_Provides_Information:
          "Esta sección proporciona información sobre las certificaciones requeridas por la Empresa para aplicar a este trabajo.",
        this_Position_Has_No_specified_Languages:
          "¡Esta vacante aún no tiene idiomas especificados!",
        provide_Information_About_Your_Languages:
          "A continuación encontrarás los idiomas requeridos para aplicar al cargo.",
        use_The_Text_Box_Below:
          "Utiliza el cuadro de texto a continuación para comunicarte con esta empresa.",
        by_Pressing_Submit_You_Consent:
          "Al presionar 'Enviar', aceptas compartir la información de tu perfil con la empresa.",
        please_Wait: "Espere por favor...",
      },

      settings: {
        update_Email_button: "Actualizar el correo electrónico",
        cancel_button: "Cancelar",
        change_Email_button: "Cambiar correo electrónico",
        update_Password_button: "Actualizar Contraseña",
        reset_Password_button: "Restablecer Contraseña",
        eliminate_Account_button: "Eliminar Cuenta",
        placeholder_Email_Adress: "Correo Electrónico",
        placeholder_Confirm_Email_Address: "Confirma el correo electrónico",
        email_Address: "Correo Electrónico",
        confirm_Email_Address: "Confirma el nuevo correo electrónico",
        new_Email_Address: "Ingrese una nueva dirección de correo electrónico",
        confirm_Password: "Confirma la Contraseña",
        please_wait: "Espere por favor...",
        password: "Contraseña",
        current_Password: "Contraseña actual",
        new_Password: "Contraseña nueva",
        confirm_New_Password: "Confirma la nueva contraseña",
        confirm_Account_Elimination: "Confirmar la Eliminación de la Cuenta ",
        enter_Email_Address: "Ingresa la dirección de correo electrónico",
        sign_In_Method: "Configuración Inicio de Sesión",
        password_Must_Be_At_Least:
          "La contraseña debe tener al menos 8 caracteres y contener símbolos",
        you_Are_Deleting_Your_Account: "¿Deseas Eliminar tu Cuenta?",
        by_Deleting_Your_Account:
          "Al eliminar tu cuenta, se perderán todos los datos y contenidos asociados a tu perfil.",
        for_Extra_Security:
          "Para mayor seguridad, confirma tu correo electrónico y contraseña.",
      },

      details: {
        talent_Details: "Detalles del Talento",
        contact: "Contacto",
        basic_Information: "Información Básica",
        nist_Nice_Roles: "Roles NIST NICE",
        education: "Educación",
        certifications: "Certificaciones",
        courses: "Cursos",
        current_Entity: "Entidad actual",
        current_Position: "Empleo actual",
        current_Location: "Ubicación Actual",
        nationality: "Nacionalidad",
        seeking_Opportunities: "Buscando Oportudidades",
        yes: "Si",
        no: "No",
        open_Travel: "Disponibilidad Para Viajar",
        desired_Employment_Type: "Tipo de empleo Deseado",
        highest_Level_Education: "Nivel más alto de Educación",
        job_Title: "Título del Empleo",
        company: "Empresa",
        not_Specified: "No especificado",
        industry: "Industria",
        start_Date: "Fecha de Inicio",
        end_Date: "Fecha de Finalización",
        currenty_Work_Here: "Trabajo aquí actualmente",
        certification_Name: "Nombre de la Certificación",
        issuing_Entity: "Entidad Emisora",
        date_Issued: "Fecha de Emisión",
        this_certification_Expires: "Esta ceriticación vence",
        this_Certification_Not_Expires: "Esta certificación no vence",
        degree: "Título Obtenido",
        school: "Institución Educativa",
        field_Study: "Campo de Estudio",
        graduation_Date: "Fecha de Graduación",
        not_Graduated: "No Graduado",
        activities: "Actividades",
        name: "Nombre",
        number: "Número",
        associated_With: "Asociado con",
      },
    },

    companyView: {
      index: {
        monthly_button: "Mensual",
        annual_button: "Anual",
        start_button: "Iniciar",
        contact_us_button: "Contáctanos",
        subscribe_button: "Suscribirse",
        edit_Profile_button: "Editar Perfil",
        back_button: "Atrás",
        submit_button: "Enviar",
        try_Again_button: "¡Intentar de Nuevo!",
        ok_Got_It_button: "¡Hecho!",
        placeholder_Search: "Buscar",
        company_School_Name: "Empresa",
        security_clearance_level: "Nivel de seguridad Habilitado",
        contact_Number: "Número de Contacto",
        current_Position: "Cargo Actual",
        country: "País",
        seeking_Opportunities: "Buscando Oportunidades",
        yes: "SI",
        no: "NO",
        company_Name: "Nombre de la Empresa",
        location: "Ubicación",
        ceo: "CEO",
        company_Website: "Página Web de la Empresa",
        jobs: "Empleos",
        employees: "Usuarios",
        contacted_Candidates: "Candidatos Contactados",
        profile_Completion: "Completar Perfil",
        full_Profile: "Perfil Completo",
        overview: "Información General",
        account: "Cuenta",
        settings: "Configuraciones",
        subscription: "Suscripciones",
        profile_Overview: "Información General del Perfil",
        completed_Sections: "Secciones Completas",
        company_Information: "Información de la Empresa",
        basic_data: "Datos Básicos",
        added_Employees: "Agregar Usuarios",
        created_Positions: " Posiciones Creadas",
        edit_Company_Profile: "Editar perfil de empresa",
        basic_Information: "Información Básica",
        company_Details: "Detalles de la Empresa",
        specified_Organization: "Organización Especificada",
        contact_Information: "Información de Contacto",
        we_Need_Your_Attention: "¡Necesitamos tu atención!",
        choose_Your_Plan: "Selecciona tu Plan",
        company_Profile_Details: "Detalles del Perfil de la Empresa",
        please_Make_Sure_To_Complete_Your_Talent:
          "Asegúrate de completar tu perfil de Talento para obtener una mejor calificación y permitir que las empresas que publican trabajos te encuentren más fácil.",
        if_You_Need_More_Info_About_Our_Pricing:
          "Si necesita más información sobre nuestros precios, envíenos un correo electrónico a",
        fill_The_Basic_Data: "Complete los datos básicos",
        fill_the_Contact_Information: "Complete la información de contacto",
        clear_filter_button: "Limpiar filtros",
        items_Found: "items encontrados",
        by_Recently_Added: "por Agregados Recientes ↓",
        SelectLocation: "Selecciona una ubicación",
        SelectSalaryRange: "Selecciona un rango salarial",
        SelectSEducationLevel: "Selecciona nivel de educación",
        training_Pie_De_Pagina: "Mostrando",
        training_Pie_De_Pagina2: "a",
        training_Pie_De_Pagina3: "de",
        training_Pie_De_Pagina4: "entradas",
        recently_Updated: "Agregado Recientemente",
        last_Month: "El mes pasado",
        last_Quarter: "El trimestre pasado",
        last_Year: "El año pasado",
        please_Check_Fields_msj: "Por favor revisa los campos rojos.",
        have_SomeErrors: "Estamos presentando algunos errores.",
        company_Information_Been_Saved:
          "¡La información de tu empresa ha sido guardada!",
        Upgrade_Subscription: "Actualizar suscripción",
        bill_to: "Factura para",
        customer_Name: "Nombre del cliente",
        currency: "Moneda",
        subscribed_Product: "Plan de Suscripción",
        billing_Method: "Método de facturación",
        phone: "Teléfono",
      },

      ajustes: {
        update_Password_button: "Actualizar Contraseña",
        reset_Password_button: "Restablecer la contraseña",
        email_Address: "Correo electrónico",
        confirm_Password: "Confirme la contraseña",
        update_Email: "Actualizar correo electrónico",
        cancel: "Cancelar",
        change_Email: "Cambiar el correo electrónico",
        password: "Contraseña",
        current_Password: "Contraseña Actual",
        new_Password: "Nueva Contraseña",
        confirm_New_Password: "Confirme la nueva contraseña",
        eliminate_Account: "Eliminar Cuenta",
        confirm_Account_Elimination: "Confirmar Eliminación de la cuenta",
        enter_Email_Address: "Ingrese el correo electrónico",
        sign_In_Method: "Configuración Inicio de Sesión",
        enter_New_Email_Address: "Ingrese un nuevo correo electrónico",
        confirm_New_Email_Address: "Confirme el nuevo correo electrónico",
        please_Wait: "Espere por favor...",
        Password_Must_Be_At_Least_8_Character:
          "La contraseña debe tener al menos 8 caracteres y contener caracteres especiales",
        deseas_Eliminar_Tu_Cuenta: "¿Deseas Eliminar tu Cuenta?",
        by_Deleting_Your_Account:
          "Al eliminar la cuenta, todos los datos y contenidos asociados al perfil de la empresa se perderá.",
        for_Extra_Security_This_Requires:
          "Para mayor seguridad, esto requiere que se confirme el correo electrónico y contraseña del administrador.",
      },

      employees: {
        add_Employees_button: "Agregar Empleado",
        delete_button: "Eliminar",
        submit_button: "Enviar",
        current_Employees: "Colaboradores registrados",
        instructions: "Instrucciones: ",
        first_Name: "Nombre",
        last_Name: "Apellido",
        job_Title: "Título Profesional",
        password: "Contraseña",
        email: "Correo Electrónico",
        confirm_Password: "Confirmar contraseña",
        please_wait: "Espere por favor...",
        add_Company_Users: "Agregar Usuarios a la Empresa",
        subscription_details: "Detalles de la suscripción",
        add_A_Company_User: "Agregar un usuario a la empresa",
        company_Admin_Users: "Usuarios Administradores de la Empresa: ",
        company_Admin_User_You_Will:
          "Un usuario administrador de la empresa podrá editar el perfil de la empresa, agregar otros usuarios de la empresa, crear anuncios de empleos y buscar talento.",
        company_Users: "Usuarios de la Empresa: ",
        allow_Other_Members_Of_Your_Company:
          "Permitir otros miembros de su empresa para unirse a la búsqueda de Talento en Ciberseguridad en Seccuri. Estos Usuarios de la empresa podrán gestionar las Publicaciones de Empleos y búsqueda Talento.",
        ask_Other_Members_Of_Your_Company:
          "¡Pide a otros miembros de tu empresa que formen parte de Seccuri! Al incluir sus correos electrónicos en esta sesión, les estaremos enviando una invitación oficial para unirse a Seccuri y ser parte de la empresa en busca de Talento en Ciberseguridad. Una vez que se registren, también podrán administrar el perfil de la empresa, publicar trabajos y revisar el talento.",
        as_A_Company_Admin_User:
          "Como usuario administrador de la empresa, usted es responsable de crear y compartir las contraseñas de otros Usuarios de la empresa. Asegúrate de crear una contraseña para cada Usuario de la Empresa y compartirla internamente con ellos. Todos los campos son obligatorios",
        are_you_sure: "¿Está seguro de que desea eliminar a este empleado?",
        delete_employee: "Eliminar empleado",
        delete: "Eliminar",
      },

      jobs: {
        create_new_pos_button: "Crear Nueva Vacante",
        save_As_button: "Guardar como ",
        change_Status_button: "Cambiar Estado",
        edit_Job_button: "Editar Posición",
        show_Profile_button: "Mostrar Perfil",
        delete_button: "Eliminar",
        delete_Job: "Eliminar Empleo",
        back_button: "Atrás",
        jobs: "Empleos",
        position_Details: "Detalles de la Vacante",
        nist_Nice_Roles: "Roles NIST NICE",
        certifications: "Certificaciones",
        languages: "Idiomas",
        job_Title: "Título del Empleo",
        company_Name: "Nombre de la Empresa",
        job_Department: "Departamento de Trabajo",
        job_Description: "Descripción del Empleo",
        vacancy_Details: "Detalles de la Vacante",
        salary_Range_Min: "Rango mínimo de salario",
        salary_Range_Max: "Rango máximo de salario",
        select_Employment_Type: "Selecciona el tipo de empleo",
        duration: "Duración",
        requirements: "Requerimientos",
        industry: "Industria",
        level_Of_Education: "Nivel de Educación",
        location: "Ubicación",
        select_A_Country: "Selecciona un país...",
        nationatily: "Nacionalidad",
        expiration_Date: "Fecha de Caducidad",
        salary_Range: "Rango Salarial",
        top: "Top ",
        candidates: "Candidatos",
        details: "Detalles",
        contact: "Contacto",
        email: "Correo electrónico",
        langauge: "Idioma",
        english: "Inglés",
        change_Job_Status: "Cambiar el estado del empleo",
        published: "Publicado",
        delete_Job_Position: "Elimar puesto de trabajo",
        only_required_Company: "Sólo en caso de ser requerido por la empresa",
        create_New_Job_Position: "Crear nuevo puesto de trabajo",
        basic_Information: "Información Básica",
        are_You_Ready_To_Start_Finding_Talent:
          "¿Estás listo para comenzar a encontrar Talento para tu empresa?",
        jobs_Subtitle2: "Mostrando 1 a 10 entrenamientos de 50 entradas",
        edit_Job_Position: "Editar puesto de trabajo",
        fill_In_Your_Job_Data: "Complete los datos de su empleo",
        specialities_And_Tasks: "Especialidades y Tareas",
        list_Certifications_Required: "Lista de certificaciones requeridas",
        fill_Native_And_Learned_Languages: "Llene los idiomas requeridos",
        please_Wait: "Espere por favor...",
        select_An_Option: "Selecciona una opción...",
        units: "Unidades (días, meses, etc.)",
        open_To_Travel: "Disponibilidad Para Viajar",
        include_Job_Functions_And_Responsibilities:
          "(Incluir funciones y responsabilidades laborales)",
        these_Are_The: "Estos son los ",
        candidates_With_More_Match:
          "candidatos que mejor se acomodan a este empleo",
        the_Percentage_Displayed_For_Each_Talent:
          "¡El porcentaje que se muestra para cada Talento indica cuánto coincide el candidato con cada uno de los roles de su publicación de trabajo!",
        the_Current_Status_For_This_Job_Position:
          "El estado actual de este puesto de trabajo es",
        are_You_Sure_You_Want_To_Change:
          ". ¿Está seguro de que desea cambiar el estado de este puesto de trabajo a",
        saved_as_Draft: "Guardado como borrador",
        are_You_Sure_You_Want_To_Delete:
          "¿Está seguro de que quiere eliminar la vacante del trabajo?",
        use_the_text:
          "Utilice el siguiente cuadro de texto para ponerse en contacto con este candidato. Envíele un mensaje corto para iniciar el contacto.",
        contact_to: "Contactar",
        the_talent: "al Talento",
      },

      profile: {
        select_Industry_Placeholder: "Selecciona una Industria...",
        select_Country_Placeholder: "Selecciona un País...",
        select_year_Placeholder: "Selecciona un Año...",
        select_Option_Placeholder: "Selecciona una Opción...",
        basic_Information: "Información Básica",
        company_Name: "Nombre de la Empresa",
        industry: "Industria",
        location: "Ubicación",
        description: "Descripción",
        company_Details: "Detalles de la Empresa",
        ceo: "CEO",
        year_Of_Fundation: "Año de Fundación",
        number_Of_Employees: "Número de Usuarios",
        revenue_Range: "Rango de Ingresos",
        contact_Information: "Información de Contacto",
        admin_First_Name: "Nombre del Administrador",
        admin_Last_Name: "Apellido del Administrador",
        admin_Job_Title: "Cargo del Administrador",
        phone_Number: "Número de Teléfono",
        phone_Number_Example: "Ejemplo Número de Teléfono",
        website: "Sitio WEB",
        birthDate: "Fecha de Nacimiento",
        secondary_Email: "Correo electrónico secundario",
        country_Code: "Código del País",
        this_Email_Will:
          "Este segundo correo te permitirá recuperar tu correo electrónico inicialmente registrado, en caso de que lo hayas olvidado.",
        upload_Company_Logo: "Cargar el logo de la empresa",
        allowed_File_Types: "Tipos de archivos permitidos: png, jpg, jpeg.",
        select_An_Industry: "Selecciona una Industria...",
        select_A_Country: "Selecciona un País...",
        select_An_Option: "Selecciona una Opción...",
        give_Us_A_Short_Description:
          "Danos una breve descripción de la empresa (declaración de misión, historia, estructura empresarial, etc.)",
      },

      roles: {
        delete_button: "Eliminar",
        save_button: "Guardar",
        placeholder_Search_Tasks: "Buscar Tareas",
        nist_Nice_Roles: "Roles NIST NICE",
        options: "opciones.",
        next_Step: "Próximo paso",
        confirm_Changes: "Confirmar Cambios",
        certifications: "Certificaciones",
        add_New: "Agregar Nuevo",
        certification: "Certificación",
        name: "Nombre",
        languages: "Idiomas",
        add_New_Language: "Agregar Nuevo Idioma",
        please_Define_The_Roles_Associated:
          "¡Por favor defina los roles asociados a su puesto de trabajo!",
        in_This_Section_Which_Consists:
          "En esta sección, que consta de tres pasos, se seleccionan los roles NIST NICE del trabajo.",
        please_start_By_Selecting_At_Least:
          "Comience seleccionando al menos un área de especialización que se relaciona con este puesto de trabajo",
        by_Clicking_Here: "haciendo click aquí",
        improve_Your_Roles: "¡Mejora tus roles!",
        you_can_Improve_The_Progress_Of_Your_Roles:
          "Puedes mejorar el progreso de tus roles en cualquier momento actualizando tus áreas, especialidades y tareas",
        select_Your_Areas: "1. Selecciona tus Áreas aquí",
        your_Nist_Nice_Roles: "Sus funciones NIST NICE",
        the_Job_Position_Roles_And_Their_Progress:
          "Los roles del puesto de trabajo y su progreso se basan en su especialidades y tareas seleccionadas",
        edit_Your_Roles: "Edita tus roles",
        select_Your_Specialities: "1. Selecciona tus Especializaciones",
        select_Your_Tasks: "2. Selecciones sus Tareas",
        please_Select_At_Least_One_Area: "¡Selecciona al menos un área!",
        before_You_Proceed_With_Step:
          "Antes de continuar con el Paso 2, selecciona al menos un área de experiencia...",
        please_Select_At_Least_One_Speciality:
          "¡Selecciona al menos una especialidad!",
        to_Continue_With_The_Next_Step:
          "Para continuar con el siguiente paso, selecciona al menos uno especialidad para el trabajo.",
        please_Select_At_Least_One_Task: "¡Selecciona al menos una tarea!",
        to_Continue_With_The_Next_Step_Is_Important:
          "Para continuar con el siguiente paso es importante que selecciones al menos una tarea.",
        choose_Your_Areas: "Elige tus áreas",
        select_The_Main_Areas_That_Make:
          "Selecciona las principales áreas que componen tu puesto de trabajo. Escoger",
        choose_Your_Specialities: "Elige tus especialidades",
        select_The_Main_Specialities_Required:
          "Selecciona las principales especialidades requeridas para el puesto de trabajo. Elige hasta",
        choose_Your_Tasks: "Elige tus tareas",
        select_All_Of_The_Tasks_That_Will_Need:
          "Selecciona todas las tareas que deberán realizarse en el trabajo.",
        show_Only_Selected: "Mostrar solo seleccionados",
        provide_Information_About_Your_Certifications:
          "Proporcione información sobre sus certificaciones.",
        provide_Information_About_The_Languages:
          "Proporcionar información sobre los idiomas requeridos para el trabajo.",
      },

      language: {
        delete_button: "Eliminar",
        save_button: "Guardar",
        language: "Idioma",
        begginer: "Principiante",
        intermediate: "Intermedio",
        proficient: "Competente",
        native_Biligual: "Nativo/Bilingüe",
        select_An_Option: "Selecciona una opción...",
        select_A_Level: "Selecciona un Nivel",
        knowledge_Of_Basic_Vocabulary_Words:
          "Conocimiento de palabras de vocabulario básico, habla oraciones simples, habilidades elementales de lectura y escritura.",
        can_Handle_Intermediate_Communication:
          "Puede manejar comunicación intermedia, lectura y escritura.",
        a_Well_Advanced_Use_Of_The_Language:
          "Tiene un uso muy avanzado del idioma, utilizando fluidez en el habla, la lectura y la escritura.",
        more_Than_Fluent_In_The_Language: "Más que fluido en el idioma.",
      },

      searchTalent: {
        find_The_Best_Cybersecurity_Talent:
          "Encuentra el mejor talento en ciberseguridad que tu empresa necesita",
      },
    },

    loginView: {
      index: {
        continue_button: "Continuar",
        register_button: "Registrar",
        email: "Correo Electrónico",
        password: "Contraseña",
        forgot_Password: "¿Has olvidado tu contraseña ?",
        company_Account: "Cuenta para Empresa",
        talent_Account: "Cuenta para Talento",
        first_Name: "Nombre",
        last_Name: "Apellido",
        admin_First_Name: "Nombre del Administrador",
        admin_Last_Name: "Apellido del Administrador",
        admin_Email: "Correo electrónico del Administrador",
        phone: "Teléfono",
        country: "País",
        confirm_Password: "Confirmar Contraseña",
        login_Tittle1: "Iniciar Sesión en Seccuri",
        login_Tittle2: "Elija el tipo de cuenta",
        login_Titlle3: "Crear una Cuenta",
        login_Tittle4: "Crear una cuenta de Empresa",
        login_Subtittle1: "¿Eres nuevo aquí?",
        login_subtittle2: "Crea una Cuenta",
        login_Subtittle3: "Espere por favor...",
        login_subtittle4: "En búsqueda de empleo",
        login_Subtittle5: "Buscando Oportunidades de Formación",
        login_Subtittle6: "Buscando Contratar Talento",
        login_Subtittle7:
          "Buscando oportunidades de capacitación para los usuarios",
        login_Subtittle8: "¿Ya tienes una cuenta?",
        login_Subtittle9: "Ingresa aqui",
        login_Subtittle10: "Selecciona un País...",
        login_subtittle11:
          "Use 12 o más caracteres con una combinación de letras (minúsculas y mayúsculas), números y símbolos.",
        login_Subtittle12: "Acepto los",
        login_Subtittle13: "Términos y Condiciones.",
      },

      forgotPassword: {
        reset_Password_button: "Restablecer Contraseña",
        cancel_button: "Cancelar",
        continue_button: "Continuar",
        forgotPassword: "Olvidaste tu Contraseña ?",
        email: "Correo Electrónico",
        please_Wait: "Espere por favor...",
        reset_Your_Password: "Restrablecer su contraseña",
        token: "Token",
        password: "Contfraseña",
        confirm_Password: "Confirmar Contraseña",
        forgot_Subtitle1:
          "Ingrese su correo electrónico para restablecer su contraseña.",

        forgot_Subtitle2:
          "Use 12 o más caracteres con una combinación de letras, números y símbolos.",
        forgot_Subtitle3:
          "Ingrese y confirme su nueva contraseña con el código temporal recibido por correo electrónico.",
      },
    },

    profileView: {
      index: {
        next_Step_butoon: "Siguiente paso",
        confirm_Changes_button: "Confirmar cambios",
        search_Tasks_Placeholder: "Buscar tareas",
        back_button: "Atrás",
        submit_button: "Enviar",
        save_Continue_button: "Guardar y Continuar",
        continue_button: "Continuar",
        go_Education_button: "Ir a Educación",
        go_Experiences_button: "Ir a las Experiencias",
        go_Certifications_button: "Ir a las Certificaciones",
        go_Courses_button: "Ir a los Cursos",
        go_Languages_button: "Ir a los Idiomas",
        go_References_button: "Ir a las Referencias",
        next_Section_button: "Sguiente Sección",
        edit_Profile: "Editar Perfil",
        personal_Details: "Información Personal",
        nist_Nice_Roles: "Roles NIST NICE",
        experience: "Experiencia",
        education: "Eduación",
        certifications: "Certificaciones",
        languages: "Idiomas",
        references: "Referencias",
        courses: "Cursos",
        basic_Information: "Información Básica",
        first_Name: "Primer Nombre",
        middle_Name: "Segundo Nombre",
        last_Name: "Apellido",
        gender: "Género",
        nationality: "Nacionalidad",
        location: "Ubicación",
        contact_Information: "Información de Contacto",
        phone_Number: "Número de Teléfono",
        website: "Sitio Web",
        secondary_Email: "Segundo Correo Electrónico",
        this_Email_Will:
          "Este segundo correo te permitirá recuperar tu correo electrónico inicialmente registrado, en caso de que lo hayas olvidado.",
        education_Information: "Información Actual",
        school_Name: "Nombre de la Institución Educativa",
        degree_Title: "Último Título Obtenido",
        personality: "Personalidad",
        select_PersonalityType: "Seleccionar resultado de personalidad",
        have_Personality_Test: "¿Ya hiciste tu test de personalidad?",
        personality_Test_result: "Resultado del test de personalidad",
        personality_Test_Up: "Haz tu test de personalidad",
        professional_Information: "Nuevas oportunidades",
        please_Take_this_Test:
          "Realice su test de personalidad y complete la información requerida en la parte inferior.",
        current_Job_Title: "Cargo Actual",
        desired_Employment_Type: "Tipo de Empleo Deseado",
        company_Name: "Nombre de la Empresa",
        select_your_Areas: "1. Selecciona tus Áreas aquí",
        select_your_Specialities: "2. Selecciona tus Especialidades",
        select_your_Tasks: "3. Selecciona tus Tareas",
        options: "opciones",
        please_Wait: "Espere por favor...",
        upload_a_Photo: "Subir una foto",
        profile_Tittle2: "¡Selecciona al menos un área!",
        profile_Subtittle1: "Completa tus datos personales",
        profile_Subtittle2: "Define tus especialidades y tareas",
        profile_Subtittle3: "Completa tu experiencia laboral",
        profile_Subtittle4: "Completa tu información académica",
        profile_Subtittle5: "Indica las certificaciones obtenidas",
        profile_Subtittle6: "Indica los idiomas que manejas",
        profile_Subtittle7: "Indica tus referencias externas",
        profile_Subtittle8: "Indica los cursos obtenidos",
        allowed_File_Types: "Tipos de archivos permitidos: png, jpg, jpeg.",
        gender_Profile: "Selecciona un género",
        select_a_Country: "Selecciona un País...",
        profile_Subtittle12: "Selecciona tu Nivel Más Alto de Educación",
        profile_Subtittle13: "Selecciona una opción",
        profile_Subtittle14: "Disponibilidad Para Viajar",
        profile_Subtittle15:
          "¿Estás buscando oportunidades laborales actualmente?",
        profile_Subtittle16:
          "Para continuar con el siguiente paso es importante que selecciones al menos un área.",
        profile_Subtittle17: "Elija sus Áreas",
        profile_Subtittle18:
          "Selecciona las principales áreas en las que has trabajado. Elige hasta",
        profile_subtittle19: "opciones.",
        profile_Subtitle20: "¡Selecciona al menos una especialidad!",
        profile_Subtitle21:
          "Para continuar con el siguiente paso es importante que selecciones al menos una especialidad.",
        profile_Subtitle22: "¡Selecciona al menos una tarea!",
        profile_Subtitle23:
          "Para continuar con el siguiente paso es importante que selecciones al menos una tarea.",
        profile_Subtitle24: "Selecciona tus especialidades",
        profile_Subtitle25:
          "Selecciona las principales especialidades en las que has trabajado. Elige hasta",
        profile_Subtitle26: "Selecciona tus tareas",
        profile_Subtitle27: "Selecciona todas las tareas que ha realizado.",
        profile_Subtitle28: "Mostrar solo los seleccionados",
        new_in_cybersecurity: "Soy nuevo en Ciberseguridad",
        hi_new_talent_cybersecurity: "Hola, ¡entusiasta en ciberseguridad!",
        hi_talent_cybersecurity:
          "Bienvenido de nuevo, ¡Talento en ciberseguridad!",
        experience_in_cybersecurity: "Tengo experiencia en ciberseguridad",
        in_trainings:
          "Te invitamos a navegar por nuestra sección de Entrenamiento, donde podrás sumergirte en diferentes temas a través de cursos y certificaciones, que te permitirán empezar tu carrera en ciberseguridad.",
        at_the_moment:
          "Una vez hayas obtenido alguna experiencia en ciberseguridad, ingresa a la opción",
        para_que:
          "para que podamos evaluar tus habilidades actuales según el marco de trabajo NIST NICE. Con ello podremos ir rastreando cada vez tu progreso y asegurarnos de que tu perfil esté más completo a la hora de aplicar a nuevas oportunidades de empleo (en caso deseado).",
        select_begginer:
          "Al seleccionar esta opción, te compartiremos toda la información sobre los cursos y entrenamientos que podrás tomar para iniciar tu carrera profesional en ciberseguridad.",
        nist_nice: "Marco de Trabajo NIST NICE",
        here: "aquí.",
        start_your_career: "Comienza tu Carrera Profesional en Ciberseguridad",
        confirm: "Confirmar Selección",
        nist_nice_framework:
          "En Seccuri usamos diferentes mecanismos para evaluar tu carrera profesional en ciberseguridad; uno de ellos es a través del marco de trabajo NIST NICE. Si quieres conocer más sobre este método ingresa",
        your_nist_nice:
          "Para conocer y modificar tus roles de NIST NICE selecciona la opción",
        seccuri_career_path: "Rutas profesionales de Seccuri",
        seccuri_career_Path_this_module:
          "Este módulo se ha desarrollado a partir de un análisis de marketing realizado por el equipo de Seccuri para ofrecer las carreras de ciberseguridad disponibles en el mercado.",
        seccuri_career_Path_each_career:
          "Cada ruta Profesional proporciona un progreso detallado, los conocimientos y los entrenamientos que necesitas para seguir mejorando tu nivel.",
        seccuri_career_Path_being_updated:
          "Este módulo se actualiza constantemente. Seguiremos añadiendo más rutas profesionales, entrenamientos y funcionalidades",
        have_Security_Clearance: "Tengo autorización de seguridad",
        security_Clearance_required: "Autorización de seguridad requerida",
        polygraph_Performed: "Poligrafía realizada",
        security_level: "Nivel de seguridad",
        career_Progress: "Progreso Profesional ",
        view_Button: "Vista",
        description: "Descripción",
        current_Career_Path_Progress:
          "El progreso actual de su carrera profesional",
        entry_Level: "Nivel Básico",
        mid_Career: "Carrera Media",
        Professional: "Profesional",
        for: "Para",
        career_Path_Current_Level:
          " como carrera profesional, tu nivel actual es ",
        progress_Bar_Above_Serves_Tool:
          "La barra de progreso anterior sirve como una herramienta para ayudarlo a realizar un seguimiento de sus avances en la trayectoria profesional de seguridad cibernética seleccionada. El cálculo inicial proviene del análisis NIST NICE realizado en su sección de Perfil, basado en sus KSA actuales (Conocimiento, Habilidades, Capacidades) y Tareas realizadas.",
        if_You_Have_Experience_Cybersecurity:
          "Si tiene experiencia en ciberseguridad y aún no ha completado el análisis NIST NICE, vuelva a la sección Editar perfil. En caso de que sea nuevo en Cyber (entusiasta de la ciberseguridad), ¡siéntase libre de comenzar algunas de las capacitaciones que se enumeran a continuación!",
        continue_Growing_Every_Time_Complete_Training:
          "Esta barra seguirá creciendo cada vez que complete una capacitación que lo certifique en las nuevas habilidades requeridas para avanzar en la carrera profesional seleccionada.",
        browse_Suggested_Trainings_Below:
          "Explore las capacitaciones sugeridas a continuación para comenzar a mejorar tus habilidades y convertirte en uno de los mejores expertos en ciberseguridad en tu campo.",
        trainings_For_This_Career: "Entrenamientos para esta carrera",
        back_All_Career_Paths: "Volver a todas las trayectorias profesionales",
        view_All_Trainings: "Ver todos los entrenamientos",
        career_Path: "Ruta Profesional",
      },
      personalities: {
        Inspector:
          "At first glance, ISTJs are intimidating. They appear serious, formal, and proper. They also love traditions and old-school values that uphold patience, hard work, honor, and social and cultural responsibility. They are reserved, calm, quiet, and upright. These traits result from the combination of I, S, T, and J, a personality type that is often misunderstood.",
        Counselor:
          "INFJs are visionaries and idealists who ooze creative imagination and brilliant ideas. They have a different, and usually more profound, way of looking at the world. They have a substance and depth in the way they think, never taking anything at surface level or accepting things the way they are. Others may sometimes perceive them as weird or amusing because of their different outlook on life.",
        Mastermind:
          "INTJs, as introverts, are quiet, reserved, and comfortable being alone. They are usually self-sufficient and would rather work alone than in a group. Socializing drains an introvert's energy, causing them to need to recharge. INTJs are interested in ideas and theories. When observing the world they are always questioning why things happen the way they do. They excel at developing plans and strategies, and don't like uncertainty.",
        Giver:
          "ENFJs are people-focused individuals. They are extroverted, idealistic, charismatic, outspoken, highly principled and ethical, and usually know how to connect with others no matter their background or personality. Mainly relying on intuition and feelings, they tend to live in their imagination rather than in the real world. Instead of focusing on living in the “now” and what is currently happening, ENFJs tend to concentrate on the abstract and what could possibly happen in the future.",
        Craftsman:
          "ISTPs are mysterious people who are usually very rational and logical, but also quite spontaneous and enthusiastic. Their personality traits are less easily recognizable than those of other types, and even people who know them well can't always anticipate their reactions. Deep down, ISTPs are spontaneous, unpredictable individuals, but they hide those traits from the outside world, often very successfully.",
        Provider:
          "ESFJs are the stereotypical extroverts. They are social butterflies, and their need to interact with others and make people happy usually ends up making them popular. The ESFJ usually tends to be the cheerleader or sports hero in high school and college. Later on in life, they continue to revel in the spotlight, and are primarily focused on organizing social events for their families, friends and communities. ESFJ is a common personality type and one that is liked by many people.",
        Idealist:
          "INFPs, like most introverts, are quiet and reserved. They prefer not to talk about themselves, especially in the first encounter with a new person. They like spending time alone in quiet places where they can make sense of what is happening around them. They love analyzing signs and symbols, and consider them to be metaphors that have deeper meanings related to life. They are lost in their imagination and daydreams, always drowned in the depth of their thoughts, fantasies, and ideas.",
        Performer:
          "ESFPs have an Extraverted, Observant, Feeling and Perceiving personality, and are commonly seen as Entertainers. Born to be in front of others and to capture the stage, ESFPs love the spotlight. ESFPs are thoughtful explorers who love learning and sharing what they learn with others. ESFPs are “people people” with strong interpersonal skills. They are lively and fun, and enjoy being the center of attention. They are warm, generous, and friendly, sympathetic and concerned for other people's well-being.",
        Champion:
          "ENFPs have an Extraverted, Intuitive, Feeling and Perceiving personality. This personality type is highly individualistic and Champions strive toward creating their own methods, looks, actions, habits, and ideas — they do not like cookie cutter people and hate when they are forced to live inside a box. They like to be around other people and have a strong intuitive nature when it comes to themselves and others. They operate from their feelings most of the time, and they are highly perceptive and thoughtful.",
        Doer: "ESTPs have an Extraverted, Sensing, Thinking, and Perceptive personality. ESTPs are governed by the need for social interaction, feelings and emotions, logical processes and reasoning, along with a need for freedom. Theory and abstracts don't keep ESTP's interested for long. ESTPs leap before they look, fixing their mistakes as they go, rather than sitting idle or preparing contingency plans.",
        Supervisor:
          "ESTJs are organized, honest, dedicated, dignified, traditional, and are great believers of doing what they believe is right and socially acceptable. Though the paths towards “good” and “right” are difficult, they are glad to take their place as the leaders of the pack. They are the epitome of good citizenry. People look to ESTJs for guidance and counsel, and ESTJs are always happy that they are approached for help.",
        Commander:
          "An ENTJ's primary mode of living focuses on external aspects and all things are dealt with rationally and logically. Their secondary mode of operation is internal, where intuition and reasoning take effect. ENTJs are natural born leaders among the 16 personality types and like being in charge. They live in a world of possibilities and they often see challenges and obstacles as great opportunities to push themselves. They seem to have a natural gift for leadership, making decisions, and considering options and ideas quickly yet carefully. They are “take charge” people who do not like to sit still.",
        Thinker:
          "INTPs are well known for their brilliant theories and unrelenting logic, which makes sense since they are arguably the most logical minded of all the personality types. They love patterns, have a keen eye for picking up on discrepancies, and a good ability to read people, making it a bad idea to lie to an INTP. People of this personality type aren't interested in practical, day-to-day activities and maintenance, but when they find an environment where their creative genius and potential can be expressed, there is no limit to the time and energy INTPs will expend in developing an insightful and unbiased solution.",
        Nurturer:
          "ISFJs are philanthropists and they are always ready to give back and return generosity with even more generosity. The people and things they believe in will be upheld and supported with enthusiasm and unselfishness. ISFJs are warm and kind-hearted. They value harmony and cooperation, and are likely to be very sensitive to other people's feelings. People value the ISFJ for their consideration and awareness, and their ability to bring out the best in others.",
        Visionary:
          "Those with the ENTP personality are some of the rarest in the world, which is completely understandable. Although they are extroverts, they don't enjoy small talk and may not thrive in many social situations, especially those that involve people who are too different from the ENTP. ENTPs are intelligent and knowledgeable need to be constantly mentally stimulated. They have the ability to discuss theories and facts in extensive detail. They are logical, rational, and objective in their approach to information and arguments.",
        Composer:
          "ISFPs are introverts that do not seem like introverts. It is because even if they have difficulties connecting to other people at first, they become warm, approachable, and friendly eventually. They are fun to be with and very spontaneous, which makes them the perfect friend to tag along in whatever activity, regardless if planned or unplanned. ISFPs want to live their life to the fullest and embrace the present, so they make sure they are always out to explore new things and discover new experiences. It is in experience that they find wisdom, so they do see more value in meeting new people than other introverts.",
      },

      workExperince: {
        add_Job_button: "Agregar nueva experiencia laboral",
        delete_button: "Eliminar",
        save_button: "Guardar",
        employment: "Experiencia Laboral",
        employment_History: "Historial de Empleo",
        job_Title: "Nombre del cargo",
        company: "Empresa",
        start_Date: "Fecha de Inicio",
        end_Date: "Fecha de Finalización",
        employment_Type: "Tipo de Empleo",
        industry: "Industría",
        job_Description: "Descripción del Empleo",
        experience_Subtitle1:
          "Cuéntanos tu experiencia profesional, empezando por tu trabajo más reciente.",
        experience_Subtitle2: "¿Actualmente trabajas aquí?",
        experience_Subtitle3: "Selecciona una opción...",
        experience_Subtitle4: "Acerca de la Empresa",
        experience_Subtitle5:
          "(Incluye la industria, ubicación, misión de la organización, etc...)",
        experience_Subtitle6:
          "(Incluir funciones y responsabilidades laborales, experiencia laboral, logros, etc...)",
      },

      education: {
        add_New_Education_button: "Agregar nuevo estudio",
        delete_button: "Eliminar",
        save_button: "Guardar",
        select_date_Placeholder: "Selecciona una Fecha",
        education_Placeholder2:
          "(Escriba un resumen de los grupos y actividades en los que estuvo involucrado mientras estudiaba).",
        education: "Formación Académica",
        education1: "Educación",
        school: "Institución Educativa",
        degree: "Título Obtenido",
        field_Of_Study: "Campo de Estudio",
        graduated: "Graduado",
        graduation_Date: "Fecha de Graduación",
        groups_Activities: "Grupos / Actividades",
        education_Subtitle1:
          "Tener una educación variada en tu currículum resume el valor de tus aprendizajes y antecedentes, aportando valor a tu desempeño laboral.",
      },

      certifications: {
        add_New_Certification_button: "Agregar Nueva Certificación",
        delete_button: "Eliminar",
        save_button: "Guardar",
        select_month_Placeholder: "Selecciona un Mes",
        certifications: "Certificaciones",
        certification: "Certificación",
        name: "Nombre",
        issuing_Entity: "Entidad Emisora",
        date_Issued: "Fecha de Emisión",
        date_Expired: "Fecha de Vencimiento",
        certifications_Subtitle1:
          "Proporciona información sobre las certificaciones obtenidas.",
        certification_Subtitle2: "Esta certificación expira",
      },

      courses: {
        add_New_Course_button: "Agregar Nuevo Curso",
        delete_button: "Eliminar",
        save_button: "Guardar",
        courses: "Cursos",
        course: "Curso",
        name: "Nombre del Curso",
        number: "Número",
        associated_With: "Asociado con",
        courses_Subtitle1:
          "Proporciona información sobre los cursos que has realizado.",
      },

      languages: {
        add_New_Language_button: "Agregar Nuevo Idioma",
        delete_button: "Eliminar",
        save_button: "Guardar",
        select_Language_Placeholder: "Selecciona un Idioma...",
        languages: "Idiomas",
        language: "Idioma",
        select_level: "Selecciona un nivel",
        begginer: "Principiante",
        intermediate: "Intermedio",
        proficient: "Competente",
        native_Biligual: "Nativo/Bilingüe",
        languages_Subtitle1:
          "Proporciona información sobre los idiomas que actualmente manejas.",
        language_Subtitle2: "Selecciona un Idioma...",
        language_Subtitle3:
          " Conoces palabras básicas, hablas oraciones simples, y tienes algunas habilidades de lectura y escritura.",
        language_Subtitle4:
          "Manejas una comunicación, lectura y escritura intermedia.",
        language_Subtitle5:
          "Tienes un manejo avanzado del idioma, con fluidez en el habla, la lectura y la escritura.",
        fluent_in_the_Language: "Eres más que fluido en el idioma.",
      },

      references: {
        add_New_button: "Agregar Nueva Referencia",
        delete_button: "Eliminar",
        save_button: "Guardar",
        references: "Referencias",
        reference: "Referencia",
        email: "Correo Electrónico",
        references_Subtitle1:
          "Proporciona información sobre tus contactos de referencia.",
      },
    },

    alertsView: {
      alerts: {
        form_Successfully_Submitted:
          "¡El formulario ha sido enviado con éxito!",
        ok_Got_It_button: "¡Hecho!",
        try_again_button: "¡Intentar de nuevo!",
        Ok_button: "¡Listo!",
        reset_Password_button: "¡Restablecer la contraseña!",
        sorry_Looks_Like:
          "Lo sentimos, parece que se han detectado algunos errores, inténtalo de nuevo.",
        customer_Required: "El nombre del cliente es un campo requerido",
        customer_Email_Required:
          "El correo electrónico del cliente es un campo requerido",
        address_Required: "El correo electrónico es un campo requerido",
        town_Required: "La ciudad es un campo requerido",
        state_Required: "El estado es un campo requerido",
        post_Code_required: "El código postal es un campo requerido",
        last_Week: "La semana pasada",
        last_month: "El mes pasado",
        last_Three_months: "Hace 3 meses",
        date_Range_Required: "El intervalo de fechas es requerido",
        new_Been_Added: "Tu nuevo usuario ha sido registrado",
        error_Adding_User: "Error al agregar usuario",
        have_Some_Problems:
          "tenemos algunos problemas con el proceso de aplicar al empleo o enviar el mensaje a la empresa",
        hooray_Succesfully_Applied:
          "¡Hurra! Has aplicado con éxito a este empleo. La empresa revisará tu perfil pronto y se comunicará contigo si está interesada.",
        Status_Job_Posting:
          "El estado de esta publicación de trabajo ha cambiado.",
        error_Changing_Status: "Tenemos un error al cambiar de estado.",
        succesfullyCompleted_Training:
          "¡Hurra! Has completado con éxito esta formación.",
        problem_Apply_Job:
          "tenemos algunos problemas con el proceso de aplicar a este empleo o enviar el mensaje a la empresa",
        sending_Mail_Information:
          "Te enviaremos un correo electrónico con información sobre cómo aplicar nuestro código de promoción pronto.",
        employee_Been_Deleted: "El empleado ha sido eliminado.",
        error_Deleting_Employee: "Tenemos un error al eliminar al empleado.",
        position_Deleted: "El empleo ha sido eliminado",
        error_Deleting_Position: "Tenemos un error al eliminar la posición.",
        now_Submit_Form: "¡Todo está genial! Ahora envía este formulario",
        your_Job_Information:
          "¡La información de tu trabajo ha sido guardada! ¡Nuestro algoritmo de IA especializado está listo para combinar tu trabajo con nuestro grupo de talentos!",
        profile_Information_Saved:
          "¡La información de tu perfil ha sido guardada!",
        check_Red_Fields: "Por favor revisa los campos rojos.",
        sorry: "Lo Sentimos",
        by_acepting_you:
          "Al aceptar que dispone de una habilitación de seguridad (o que se le ha concedido una en el pasado), da su consentimiento para facilitar información veraz que pueda ser necesaria para posteriores investigaciones de antecedentes y procesos de validación de candidatos.",
        in_case_you_currently:
          "En caso de que actualmente tenga una habilitación 'activa' o 'vigente' y solicite un puesto de trabajo que requiera un nivel de habilitación determinado, aún puede estar sujeto a revisiones de antecedentes por parte del Departamento de Recursos Humanos de una empresa o por parte del Gobierno Federal.",
        form_Several_Errors:
          "El formulario tiene varios errores, por favor revisa los campos rojos.",
        course_Deleted: "El curso ha sido eliminado.",
        error_Deleting_Course: "Tenemos un error al eliminar el curso.",
        courses_Updated_Successfully:
          "Tus cursos se han actualizado con éxito.",
        certification_Deleted: "La certificación ha sido eliminada.",
        error_Deleting_Certification:
          "Tenemos un error al eliminar la certificación.",
        certifications_Updated_Successfully:
          "Tus certificaciones se han actualizado con éxito.",
        education_Deleted: "La formación académica ha sido eliminada.",
        error_deleting_Education:
          "Tenemos un error al eliminar la formación académica.",
        education_History_Updated_Successfully:
          "Tu historial académico se ha actualizado correctamente.",
        language_Deleted: "El idioma ha sido eliminado.",
        error_Deleting_Language: "Tenemos un error al eliminar el idioma.",
        languages_Updated_Successfully:
          "Tus idiomas se han actualizado con éxito.",
        reference_Deleted: "La referencia ha sido eliminada.",
        error_deleting_Reference: "Tenemos un error al eliminar la referencia.",
        references_Updated_Successfully:
          "Tus referencias se han actualizado con éxito.",
        information_Succesfully_Submitted:
          "¡Tu información ha sido enviada con éxito!",
        invalid_Infromation: "información no válida.",
        roles_Updated_Successfully:
          "Tus funciones se han actualizado correctamente.",
        message_Already_Talent: "El mensaje ya fue enviado al talento.",
        problems_Sending_Message_Talent:
          "tenemos algunos problemas para enviar el mensaje al talento.",
        account_Eliminated: "Tu cuenta ha sido eliminada.",
        email_Password_Invalid: "Correo electrónico o contraseña no válidos.",
        email_Been_reset:
          "Tu correo electrónico ha sido reiniciado. Utiliza el nuevo correo electrónico para iniciar sesión.",
        password_Been_Reset:
          "Tu contraseña ha sido restablecida. Utiliza el nuevo correo electrónico para iniciar sesión.",
        password_Confirmation_Password_Invalid:
          "Nueva contraseña o contraseña de confirmación no válida.",
        email_Successfully_Changed:
          "¡El correo electrónico se cambió con éxito!",
        password_Successfully_Changed: "¡La contraseña se cambió con éxito!",
        account_Already_Activated: "Tu cuenta ya ha sido activada.",
        token_Invalid: "Token inválido",
        instructions_Reset_Password:
          "Recibirás un correo electrónico con las instrucciones para restablecer tu contraseña",
        user_Password_Invalid: "Usuario o contraseña inválidos.",
        password_Must_More_Characters:
          "La contraseña debe tener al menos 12 o más caracteres con una combinación de letras, números y símbolos.",
        password_Changed_Succesfully:
          "Tu contraseña ha sido cambiada con éxito. Utiliza la nueva contraseña para iniciar sesión.",
        email_Changed_Succesfully:
          "El Correo electrónico ha sido cambiado correctamente y puede usarlo para iniciar sesión.",
        maximun_Number_Account_Blocked:
          "Has alcanzado el número máximo de intentos de inicio de sesión y tu cuenta está bloqueada. Para comenzar a usar la plataforma de Seccuri, deberás restablecer tu contraseña",
        fill_Valid_Phone_Number:
          "Por favor, introduce un número de teléfono válido.",
        password_More_Characters:
          "La contraseña debe tener al menos 12 o más caracteres con una combinación de letras (inferior y superior), números y símbolos.",
        accept_Termns_Conditions:
          "Por favor, lee y acepta los términos de condiciones.",
        successfully_Registered_Seccuri:
          "¡Felicidades! Te has registrado con éxito en Seccuri. Por favor revisa tu bandeja de entrada para verificar y activar tu cuenta.",
        happened_Error_During_Register:
          "¡Lo siento! Ocurrió un error durante el registro, intenta nuevamente.",
        exit_edit_profile:
          "Espera! Si cierras la edición del perfil ahora podrías perder la información. Por favor asegúrate de guardar tu perfil antes de salir. Está seguro que desea salir? ",
        confirm_exit_edit_profile: "Salir",
        Open_edit_profile_Hi: "Hola ",
        Open_edit_profile:
          "Estás a punto de iniciar la edición de tu perfil. Por favor asegúrate de ir guardando tu información a medida que la ingresas usando los botones azules que hay para cada sección, así evitarás perderla al final del proceso.",
        password_Expired:
          "Su contraseña ha caducado y la cuenta ha sido bloqueada por motivos de seguridad. Para seguir utilizando la plataforma debe cambiarla siguiendo las instrucciones",
        password_Expired_Alert:
          "Por razones de seguridad, su contraseña caducará en",
        password_Expired_Alert_2:
          "días. Por favor, cambie la contraseña de su cuenta lo antes posible para seguir utilizando la Plataforma Seccuri.",
        temporary_Emails:
          "La plataforma Seccuri no admite direcciones de correo temporales.",
        please_Wait: "Por favor espere...",
        submit: "Enviar",
      },
    },

    dashboard: "Tablero",
    layoutBuilder: "Constructor de maquetación",
    craft: "Elaborado",
    pages: "Paginas",
    profile: "Perfil",
    jobs: "Portal de Empleos",
    trainings: "Entrenamiento",
    career_Path: "Ruta Profesional",
    profileOverview: "Descripción general",
    projects: "Proyectos",
    campaigns: "Campañas",
    documents: "Documentos",
    connections: "Conexiones",
    wizards: "Magos",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Cuenta",
    accountOverview: "Descripción general",
    settings: "Configuración",
    authentication: "Autenticación",
    basicFlow: "Flujo básico",
    signIn: "Registrarse",
    signUp: "Inscribirse",
    passwordReset: "Restablecimiento de contraseña",
    multiStepSignUp: "Regístrese Multi-Pasos",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Aplicaciones",
    chat: "Chat",
    privateChat: "Chat privado",
    groupChat: "Grupo de chat",
    drawerChat: "Chat del cajón",
    widgets: "Widgets",
    widgetsLists: "Liza",
    widgetsStatistics: "Estadísticas",
    widgetsCharts: "Gráficos",
    widgetsMixed: "Mezclada",
    widgetsTables: "Mesas",
    widgetsFeeds: "Alimenta",
    changelog: "Registro de cambios",
    docsAndComponents: "Documentos & Componentes",
    megaMenu: "Mega menú",
    exampleLink: "Enlace de ejemplo",
    modals: "Modales",
    general: "General",
    inviteFriends: "Invitar A Amigos",
    viewUsers: "Ver Usuarios",
    upgradePlan: "Plan De Actualización",
    shareAndEarn: "Compartir Y Ganar",
    forms: "Formas",
    newTarget: "Nuevo Objetivo",
    newCard: "Nueva Tarjeta",
    newAddress: "Nueva Direccion",
    createAPIKey: "Crea Clave De Api",
    twoFactorAuth: "Dos Factores",
    createApp: "Crear Aplicacion",
    createAccount: "Crear Una Cuenta",
    activity: "Actividad",
    documentation: "Documentación",
    components: "Componentes",
    resources: "Recursos",
    customers: "Clientes",
    gettingStarted: "Empezando",
    customersListing: "Listado De Clientes",
    customerDetails: "Detalles De Los Clientes",
    calendarApp: "Calendario",
    subscriptions: "Suscripciones",
    getStarted: "Empezando",
    subscriptionList: "Lista De Suscripción",
    addSubscription: "Añadir Suscripción",
    ViewSubscription: "Suscripción",
    personal_information: "Información personal",
    language: "Idioma",
    my_Profile: "Perfil",
    accountSettings: "Configurar Cuenta",
    sign_Out: "Cerrar sesión",
    on_Favorites: "Favoritos",
    on_Results: "Resultados",
    recommendations: "Recomendaciones",
    profile_Completion: "Completar Perfil",
    overview: "Información General",
    copyright_Seccuri2022: "Derechos de Autor Seccuri 2022",
    terms_Conditions: "Términos y Condiciones",
    privacy_Statement: "Declaración de Privacidad",
    profile_Details: "Detalles del Perfil",
    talent_Tittle1: "¡Necesitamos tu atención!",
    talent_Subtittle1:
      "Asegúrate de completar tu perfil de Talento para obtener una mejor calificación y permitir que las empresas que publican trabajos te encuentren más fácil.",
    please_Get_Your_Roles: "¡Obtenga su Roles!",
    to_get_Roles_Please_Follow:
      "Para obtener los roles NIST NICE, siga los pasos 1, 2 y 3.",
    you_Can_Start_With_First_Step:
      "Puedes empezar con el primer paso desde aquí",
    select_your_Areas: "1. Selecciona tus Áreas aquí",
    profile_Overview: "Información General del Perfil",
    completed_Sections: "Secciones Completadas",
    home: "Inicio",
    full_Profile: "Perfil Completo",
  },
  de: {
    jobsView: {
      index: {
        create_new_pos_button: "Create new job position",
      },
    },
    dashboard: "Instrumententafel",
    layoutBuilder: "Layout-Builder",
    craft: "Hergestellt",
    pages: "Seiten",
    profile: "Profil",
    profileOverview: "Überblick",
    projects: "Projekte",
    campaigns: "Kampagnen",
    documents: "Unterlagen",
    connections: "Anschlüsse",
    wizards: "Zauberer",
    horizontal: "Horizontal",
    vertical: "Vertikal",
    account: "Konto",
    accountOverview: "Überblick",
    settings: "Die Einstellungen",
    authentication: "Authentifizierung",
    basicFlow: "Grundfluss",
    signIn: "Einloggen",
    signUp: "Anmelden",
    passwordReset: "Passwort zurücksetzen",
    multiStepSignUp: "Multi-Steps-Anmeldung",
    error404: "Fehler 404",
    error500: "Fehler 500",
    apps: "Apps",
    chat: "Plaudern",
    privateChat: "Private Chat",
    groupChat: "Privater Chat",
    drawerChat: "Gruppenchat Schubladen-Chat",
    widgets: "Widgets",
    widgetsLists: "Listen",
    widgetsStatistics: "Statistiken",
    widgetsCharts: "Diagramme",
    widgetsMixed: "Gemischt",
    widgetsTables: "Tabellen",
    widgetsFeeds: "Einspeisungen",
    changelog: "Änderungsprotokoll",
    docsAndComponents: "Dokumente & Komponenten",
    megaMenu: "Mega-Menü",
    exampleLink: "Beispiellink",
    modals: "Modale",
    general: "Allgemeines",
    inviteFriends: "Freunde Einladen",
    viewUsers: "Benutzer Anzeigen.",
    upgradePlan: "Upgrade-Plan",
    shareAndEarn: "Teilen & Verdienen",
    forms: "Formen",
    newTarget: "Neues Ziel",
    newCard: "Neue Karte",
    newAddress: "Neue Adresse",
    createAPIKey: "Api-Key Erstellen",
    twoFactorAuth: "Zwei Faktor Auth.",
    createApp: "App Erstellen",
    createAccount: "Benutzerkonto Erstellen",
    activity: "Aktivität",
    documentation: "Dokumentation",
    components: "Bauteile",
    resources: "Ressourcen",
    customers: "Kunden",
    gettingStarted: "Einstieg",
    customersListing: "Kundenauflistung",
    customerDetails: "Kundenangaben",
    calendarApp: "Kalender",
    subscriptions: "Abonnements",
    getStarted: "Einstieg",
    subscriptionList: "Abonnementliste",
    addSubscription: "Subskription Hinzufügen.",
    viewSubscription: "Abonnement Anzeigen.",
  },
  ja: {
    dashboard: "ダッシュボード",
    layoutBuilder: "レイアウトビルダー",
    craft: "作成された",
    pages: "ページ",
    profile: "プロフィール",
    profileOverview: "概要",
    projects: "プロジェクト",
    campaigns: "キャンペーン",
    documents: "書類",
    connections: "接続",
    wizards: "ウィザード",
    horizontal: "横",
    vertical: "垂直",
    account: "アカウント",
    accountOverview: "概要",
    settings: "設定",
    authentication: "認証",
    basicFlow: "基本的な流れ",
    signIn: "サインイン",
    signUp: "サインアップ",
    passwordReset: "パスワードのリセット",
    multiStepSignUp: "マルチステップサインアップ",
    error404: "エラー404",
    error500: "エラー 500",
    apps: "アプリ",
    chat: "チャット",
    privateChat: "プライベートチャット",
    groupChat: "グループチャット",
    drawerChat: "ドロワーチャット",
    widgets: "ウィジェット",
    widgetsLists: "リスト",
    widgetsStatistics: "統計",
    widgetsCharts: "チャート",
    widgetsMixed: "混合",
    widgetsTables: "テーブル",
    widgetsFeeds: "フィード",
    changelog: "変更ログ",
    docsAndComponents: "ドキュメントとコンポーネント",
    megaMenu: "メガメニュー",
    exampleLink: "リンク例",
    modals: "モーダルズ",
    general: "一般",
    inviteFriends: "友達を招待",
    viewUsers: "ユーザーを表示します",
    upgradePlan: "アップグレードプラン",
    shareAndEarn: "シェア＆稼働",
    forms: "フォーム",
    newTarget: "新しいターゲット",
    newCard: "新しいカード",
    newAddress: "新しいアドレス",
    createAPIKey: "Apiキーを作成します",
    twoFactorAuth: "2つの要因Auth",
    createApp: "アプリを作成します",
    createAccount: "アカウントを作成する",
    activity: "アクティビティ",
    documentation: "ドキュメンテーション",
    components: "コンポーネント",
    resources: "資力",
    customers: "お客様のお客様",
    gettingStarted: "入門",
    customersListing: "顧客のリスト",
    customerDetails: "お客様の詳細",
    calendarApp: "カレンダー",
    subscriptions: "購読",
    getStarted: "入門",
    subscriptionList: "サブスクリプションリスト",
    addSubscription: "サブスクリプションを追加します",
    viewSubscription: "購読を見る",
  },
  fr: {
    dashboard: "Générateur de mise",
    layoutBuilder: "En page",
    craft: "Fabriqué",
    pages: "Pages",
    profile: "Profil",
    profileOverview: "Aperçu",
    projects: "Projets",
    campaigns: "Campagnes",
    documents: "Documents",
    connections: "Connexions",
    wizards: "Sorciers",
    horizontal: "Horizontal",
    vertical: "Verticale",
    account: "Compte",
    accountOverview: "Aperçu",
    settings: "Paramètres",
    authentication: "Authentification",
    basicFlow: "Flux de base",
    signIn: "SS'identifier",
    signUp: "Inscrivez-vous",
    passwordReset: "Réinitialisation du mot de passe",
    multiStepSignUp: "S'Inscrire Multi-Étapes",
    error404: "Erreur 404",
    error500: "Erreur 500",
    apps: "Applications",
    chat: "Discuter",
    privateChat: "Discussion privée",
    groupChat: "Discussion de groupe",
    drawerChat: "Chat de tiroir",
    widgets: "Widgets",
    widgetsLists: "Listes",
    widgetsStatistics: "Statistiques",
    widgetsCharts: "Graphiques",
    widgetsMixed: "Mixte",
    widgetsTables: "Les tables",
    widgetsFeeds: "Flux",
    changelog: "Journal des modifications",
    docsAndComponents: "Documents & composants",
    megaMenu: "Méga Menu",
    exampleLink: "Exemple de lien",
    modals: "Modals",
    general: "Général",
    inviteFriends: "Inviter Des Amis",
    viewUsers: "Voir Les Utilisateurs",
    upgradePlan: "Plan De Mise À Niveau",
    shareAndEarn: "Partager Et Gagner",
    forms: "Formes",
    newTarget: "Nouvelle Cible",
    newCard: "Nouvelle Carte",
    newAddress: "Nouvelle Adresse",
    createAPIKey: "Créer Une Touche Api",
    twoFactorAuth: "Deux Facteurs D'Authentification",
    createApp: "Créer Une Application",
    createAccount: "Créer Un Compte",
    activity: "Activité",
    documentation: "Documentation",
    components: "Composants",
    resources: "Ressources",
    customers: "Les Clients",
    gettingStarted: "Commencer",
    customersListing: "Liste Des Clients",
    customerDetails: "Détails Des Clients",
    calendarApp: "Calendrier",
    subscriptions: "Abonnements",
    getStarted: "Commencer",
    subscriptionList: "Liste D'Abonnement",
    addSubscription: "Ajouter Un Abonnement",
    viewSubscription: "Voir L'Abonnement",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
