import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import jwt_decode from "jwt-decode";
import { createArrayExpression } from "@vue/compiler-core";
export interface Job {
  skip: number;
  take: number;
  filters: [
    {
      minSalary: number;
      maxSalary: number;
      location: number;
      educationLevels: [];
    }
  ];
}
export interface Area {
  errors: unknown;
  areas: Area;
}

export interface User {
  [x: string]: any;
  name: string;
  surname: string;
  email: string;
  password: string | null;
  api_token: string | null;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}
export interface JobPositions {
  errors: unknown;
  jobs: Array<any>;
  isAuthenticated: boolean;
}

export interface Category {
  errors: unknown;
  categories: any;
}

export interface Speciality {
  errors: unknown;
  specialities: any;
}

export interface Task {
  errors: unknown;
  tasks: any;
}

export interface Role {
  errors: unknown;
  roles: any;
}

@Module
export default class AuthModule
  extends VuexModule
  implements UserAuthInfo, JobPositions, Category, Speciality, Task, Role
{
  errors = {};
  user = {} as User;
  job = {} as any;
  jobs = [] as Array<any>;
  categories = [] as Array<any>;
  specialities = [] as Array<any>;
  tasks = [] as Array<any>;
  roles = [] as Array<any>;
  careers = [] as any;
  completeCareerPath = [] as any;
  isAuthenticated = !!JwtService.getToken();
  userRole = null;
  userSubscription = null;
  currentJobId = null;
  currentEmployeeId = null;
  topTalent = [] as Array<any>;
  talentList = [] as Array<any>;
  currentTalentDetails = {} as any;
  trainingsList = [] as Array<any>;
  trainingDetails = {} as any;
  careerPathSelected = {} as any;
  currentSubscriptions = {} as any;
  trainingListMeta = {} as any;
  trainingListProvider = {} as any;
  trainingListLevel = {} as any;
  trainingListFilters = {} as any;
  jobListMeta = 0;
  trainingCurrentPage = 0;
  subscriptionSelected = null;
  jobCurrentPage = 0;
  UpdatedPasswordAlert = true;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }
  /**
   * Get currentRoleUser object
   * @returns currentRoleUser
   */
  get currentUserRole(): any {
    return this.userRole || window.localStorage.getItem("userRole");
  }

  get currentUserSubscription(): any {
    return (
      this.userSubscription || window.localStorage.getItem("userSubscription")
    );
  }

  get currentUserSubscriptionIsDemo(): any {
    return (
      this.currentUserRole &&
      this.currentUser &&
      this.currentUserRole !== "Talent" &&
      (!this.currentUserSubscription ||
        this.currentUserSubscription === "Demo" ||
        this.currentUserSubscription === "")
    );
  }
  /**
   * Get current Job object
   * @returns Job
   */
  //contains all information about all jobs
  get CurrentJobs(): Array<any> {
    return this.jobs;
  }
  /**
   * Get current topTalent object
   * @returns topTalent
   */
  get getTopTalent(): Array<any> {
    return this.topTalent;
  }
  /**
   * Get current topTalent object
   * @returns topTalent
   */
  get getTalents(): Array<any> {
    return this.talentList;
  }
  /**
   * Get current Job object
   * @returns Job
   */
  //contains all information about a specified job
  get jobDetails(): any {
    return this.job;
  }
  /**
   * Get current Job object
   * @returns Job
   */
  get currentJob(): any {
    return this.currentJobId;
  }
  /**
   * Get current employee object
   * @returns user
   */
  get currentEmployee(): any {
    return this.currentEmployeeId;
  }
  /**
   * Get current Talent Details object
   * @returns user
   */
  get currentTalentInfo(): any {
    return this.currentTalentDetails;
  }
  /**
   * Get current trainings  object
   * @returns training
   */
  get currentTrainingsList(): any {
    return this.trainingsList;
  }
  /**
   * Get current training Details  object
   * @returns training
   */
  get currentTrainingDetails(): any {
    return this.trainingDetails;
  }
  /**
   * Get current available subscriptions
   * @returns subscriptions
   */
  get availableSubscriptions(): any {
    return this.currentSubscriptions;
  }
  /**
   * Get current categories object
   * @returns categories
   */
  get currentCategories(): Array<any> {
    return this.categories;
  }
  /**
   * Get current specialities object
   * @returns specialities
   */
  get currentSpecialities(): Array<any> {
    return this.specialities;
  }
  /**
   * Get current tasks object
   * @returns tasks
   */
  get currentTasks(): Array<any> {
    return this.tasks;
  }
  /**
   * Get current roles object
   * @returns roles
   */
  get currentRoles(): Array<any> {
    return this.roles;
  }
  /**
   * Get current careerPathSelected object
   * @returns careerPathSelected
   */
  get currentCareerPath(): any {
    return this.careerPathSelected;
  }
  /**
   * Get current careers object
   * @returns careers
   */
  get currentCareers(): any {
    return this.careers;
  }
  /**
   * Get current completeCareerPath object
   * @returns completeCareerPath
   */
  get completeCareersByUser(): any {
    return this.completeCareerPath;
  }
  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }
  /**
   * training list pagination
   * @returns any
   */
  get getTrainingListMeta(): any {
    return this.trainingListMeta;
  }
  /**
   * training list provider
   * @returns any
   */
  get getTrainingListProvider(): any {
    return this.trainingListProvider;
  }
  /**
   * training list Levels
   * @returns any
   */
  get getTrainingListLevel(): any {
    return this.trainingListLevel;
  }
  /**
   * training list filter
   * @returns any
   */
  get getTrainingListFilter(): any {
    return this.trainingListFilters;
  }
  /**
   * training page
   * @returns number page
   */
  get getTrainingCurrentPage(): any {
    return this.trainingCurrentPage;
  }
  /**
   * training page
   * @returns number page
   */
  get getSubscriptionSelected(): any {
    return this.subscriptionSelected;
  }

  /**
   * pagination Job
   * @returns any
   */
  get getJobListMeta(): any {
    return this.jobListMeta;
  }
  /**
   * job page
   * @returns number page
   */
  get getJobCurrentPage(): any {
    return this.jobCurrentPage;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }
  /**
   * Get authentification errors
   * @returns array
   */
  get getAlertUpdatePassword() {
    return this.UpdatedPasswordAlert;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }
  @Mutation
  [Mutations.UPDATE_CURRENT_SUBSCRIPTION](user) {
    this.userSubscription = user.activeSubscription
      ? user.activeSubscription?.name
      : null;
    window.localStorage.setItem(
      "userSubscription",
      this.userSubscription || ""
    );
  }

  @Mutation
  [Mutations.UPDATE_CURRENT_ROLE](token) {
    const tokenDecode: any = jwt_decode(token);
    this.userRole = tokenDecode ? tokenDecode.role : null;
    window.localStorage.setItem("userRole", this.userRole || "");
  }

  @Mutation
  [Mutations.UPDATE_CURRENT_JOB](id) {
    this.currentJobId = id;
  }

  @Mutation
  [Mutations.SET_STORE_MUTATION]() {
    this.job = {};
  }
  @Mutation
  [Mutations.UPDATE_CURRENT_EMPLOYEE](id) {
    this.currentEmployeeId = id;
  }
  @Mutation
  [Mutations.UPDATE_CURRENT_TALENT_DETAILS](talent) {
    this.currentTalentDetails = talent;
  }
  @Mutation
  [Mutations.SET_UPDATED_PASSWORD_ALERT_MUTATION](showAlert) {
    this.UpdatedPasswordAlert = showAlert;
  }

  @Mutation
  [Mutations.GET_TRAININGS_LIST_MUTATION](payload) {
    const dataTrainings = JSON.parse(payload.trainings);
    this.trainingsList = dataTrainings.data;
    this.trainingListMeta = dataTrainings.meta;
    this.trainingCurrentPage = payload.page;
  }
  @Mutation
  [Mutations.SUBSCRIPTION_SELECTED](title) {
    this.subscriptionSelected = title;
  }

  @Mutation
  [Mutations.GET_TRAININGS_LIST_FILTER_MUTATION](payload) {
    debugger;
    const dataTrainings = JSON.parse(payload.trainings);
    this.trainingListFilters = dataTrainings.data;
  }

  @Mutation
  [Mutations.GET_TRAININGS_LIST_FILTER_PROVIDER_MUTATION](payload) {
    const dataTrainings = JSON.parse(payload.trainings);
    this.trainingListProvider = dataTrainings.data;
  }
  @Mutation
  [Mutations.GET_TRAININGS_LIST_FILTER_LEVEL_MUTATION](payload) {
    const dataTrainings = JSON.parse(payload.trainings);
    this.trainingListLevel = dataTrainings.data;
  }

  @Mutation
  [Mutations.GET_TRAINING_DETAILS_MUTATION](training) {
    this.trainingDetails = JSON.parse(training);
  }

  @Mutation
  [Mutations.SET_TRAINING_DETAILS_MUTATION]() {
    this.trainingDetails = {};
  }

  @Mutation
  [Mutations.GET_CURRENT_SUBSCRIPTION_MUTATION](subscriptions) {
    this.currentSubscriptions = subscriptions;
  }
  @Mutation
  [Mutations.GET_SUBSCRIPTION_URL_MUTATION](url) {
    console.log(url);
  }

  @Mutation
  [Mutations.GET_TOP_TALENT_JOB_MUTATION](topTalentInformation) {
    this.topTalent = topTalentInformation ? topTalentInformation.data : [];
  }
  @Mutation
  [Mutations.GET_TALENT_LIST_MUTATION](talents) {
    this.talentList = talents ? talents.data : [];
  }

  @Mutation
  [Mutations.UPDATE_WORK_EXPERIENCE_ITEM_MUTATION](experienceData) {
    if (!this.user.experiences) {
      this.user.experiences = [];
    }
    if (experienceData.id > 0) {
      // update
      const currentItemIndex = this.user.experiences.findIndex(
        (item) => item.id === experienceData.id
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.experiences[currentItemIndex] = { ...experienceData };
    } else {
      delete experienceData["id"];
      this.user.experiences.push(experienceData);
    }
  }

  @Mutation
  [Mutations.DELETE_WORK_EXPERIENCE_ITEM_MUTATION](experienceData) {
    if (!this.user.experiences) {
      this.user.experiences = [];
    }
    if (experienceData.id > 0) {
      // update
      const currentItemIndex = this.user.experiences.findIndex(
        (item) => item.id === experienceData.id
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.experiences[currentItemIndex] = { ...experienceData };
      this.user.experiences.splice([currentItemIndex], 1);
      // experienceData.splice([currentItemIndex], 1);
    }
  }
  @Mutation
  [Mutations.UPDATE_EDUCATION_ITEM_MUTATION](educationData) {
    if (!this.user.educations) {
      this.user.educations = [];
    }
    if (educationData.id > 0) {
      // update
      const currentItemIndex = this.user.educations.findIndex(
        (item) => item.id === educationData.id
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.educations[currentItemIndex] = { ...educationData };
    } else {
      // is new only append
      delete educationData["id"];
      this.user.educations.push(educationData);
    }
  }
  @Mutation
  [Mutations.DELETE_EDUCATION_ITEM_MUTATION](educationData) {
    if (!this.user.educations) {
      this.user.educations = [];
    }
    if (educationData.id > 0) {
      // update
      const currentItemIndex = this.user.educations.findIndex(
        (item) => item.id === educationData.id
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.educations[currentItemIndex] = { ...educationData };
      this.user.educations.splice([currentItemIndex], 1);
      // experienceData.splice([currentItemIndex], 1);
    }
  }

  @Mutation
  [Mutations.UPDATE_CERTIFICATION_ITEM_MUTATION](certificationData) {
    if (!this.user.certifications) {
      this.user.certifications = [];
    }
    if (certificationData.id > 0) {
      // update
      const currentItemIndex = this.user.certifications.findIndex(
        (item) => item.id === certificationData.id
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.certifications[currentItemIndex] = { ...certificationData };
    } else {
      // is new only append
      delete certificationData["id"];
      this.user.certifications.push(certificationData);
    }
  }
  @Mutation
  [Mutations.DELETE_CERTIFICATION_ITEM_MUTATION](certificationData) {
    if (!this.user.certifications) {
      this.user.certifications = [];
    }
    if (certificationData.id > 0) {
      // update
      const currentItemIndex = this.user.certifications.findIndex(
        (item) => item.id === certificationData.id
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.certifications[currentItemIndex] = { ...certificationData };
      this.user.certifications.splice([currentItemIndex], 1);
      // experienceData.splice([currentItemIndex], 1);
    }
  }
  @Mutation
  @Mutation
  [Mutations.DELETE_JOB_MUTATION](jobData) {
    if (!this.jobs) {
      this.jobs = [];
    }
    if (jobData != null) {
      // update
      const currentItemIndex = this.jobs.findIndex(
        (item) => item.id == jobData
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.jobs[currentItemIndex] = { ...jobData };
      this.jobs.splice(currentItemIndex, 1);
      // experienceData.splice([currentItemIndex], 1);
    }
  }
  @Mutation
  [Mutations.DELETE_COURSE_ITEM_MUTATION](courseData) {
    if (!this.user.courses) {
      this.user.courses = [];
    }
    if (courseData.id > 0) {
      // update
      const currentItemIndex = this.user.courses.findIndex(
        (item) => item.id === courseData.id
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.courses[currentItemIndex] = { ...courseData };
      this.user.courses.splice([currentItemIndex], 1);
      // experienceData.splice([currentItemIndex], 1);
    } else {
      console.log("DELETE EXPERIENCES ON STATE ", this.user.courses);
    }
  }

  @Mutation
  [Mutations.UPDATE_COURSE_ITEM_MUTATION](courseData) {
    if (!this.user.courses) {
      this.user.courses = [];
    }
    if (courseData.id > 0) {
      // update
      const currentItemIndex = this.user.courses.findIndex(
        (item) => item.id === courseData.id
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.courses[currentItemIndex] = { ...courseData };
    } else {
      // is new only append
      delete courseData["id"];
      this.user.courses.push(courseData);
    }
  }
  @Mutation
  [Mutations.UPDATE_LANGUAGE_ITEM_MUTATION](languageData) {
    if (!this.user.languages) {
      this.user.languages = [];
    }
    if (languageData.id > 0) {
      // update
      const currentItemIndex = this.user.languages.findIndex(
        (item) => item.id === languageData.id
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.languages[currentItemIndex] = { ...languageData };
    } else {
      // is new only append
      delete languageData["id"];
      this.user.languages.push(languageData);
    }
  }

  @Mutation
  [Mutations.DELETE_LANGUAGE_ITEM_MUTATION](languageData) {
    if (!this.user.languages) {
      this.user.languages = [];
    }
    if (languageData.id > 0) {
      // update
      const currentItemIndex = this.user.languages.findIndex(
        (item) => item.id === languageData.id
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.languages[currentItemIndex] = { ...languageData };
      this.user.languages.splice([currentItemIndex], 1);
      // experienceData.splice([currentItemIndex], 1);
    }
  }
  @Mutation
  [Mutations.COMPLETE_TRAINING_MUTATION](training) {
    if (!this.trainingsList) {
      this.trainingsList = [];
    }

    if (training.id > 0) {
      // update
      const currentItemIndex = this.user.languages.findIndex(
        (item) => item.id === training.id
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.trainingsList[currentItemIndex] = { ...training };
    } else {
      // is new only append
      this.trainingsList.push(training);
    }
  }
  @Mutation
  [Mutations.DELETE_RECOMMENDATION_ITEM_MUTATION](recommendationData) {
    if (!this.user.recommendations) {
      this.user.recommendations = [];
    }
    if (recommendationData.email != null) {
      // update
      const currentItemIndex = recommendationData.index;
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.recommendations[currentItemIndex] = recommendationData.email;
      this.user.recommendations.splice([currentItemIndex], 1);
      // experienceData.splice([currentItemIndex], 1);
    }
  }
  @Mutation
  [Mutations.UPDATE_RECOMMENDATION_ITEM_MUTATION](recommendationData) {
    if (!this.user.recommendations) {
      this.user.recommendations = [];
    }
    if (recommendationData.email != null) {
      // update
      const currentItemIndex = recommendationData.index;
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.recommendations[currentItemIndex] = recommendationData.email;
    } else {
      // is new only append
      this.user.recommendations.push(recommendationData.email);
    }
  }
  @Mutation
  [Mutations.DELETE_JOB_CERTIFICATION_ITEM_MUTATION](certificationData) {
    if (!this.job.certificates) {
      this.job.certificates = [];
    }
    if (certificationData.name != null) {
      // update
      const currentItemIndex = certificationData.index;
      if (currentItemIndex === -1) {
        return false;
      }
      this.job.certificates[currentItemIndex] = certificationData.name;
      this.job.certificates.splice([currentItemIndex], 1);
      // experienceData.splice([currentItemIndex], 1);
    }
  }
  @Mutation
  [Mutations.ADD_JOB_CERTIFICATION_ITEM_MUTATION](certificationData) {
    if (!this.job.certificates) {
      this.job.certificates = [];
    }
    if (certificationData.name != null) {
      // update
      const currentItemIndex = certificationData.index;
      if (currentItemIndex === -1) {
        return false;
      }
      this.job.certificates[currentItemIndex] = certificationData.name;
    } else {
      // is new only append
      this.job.certificates.push(certificationData.name);
    }
  }

  @Mutation
  [Mutations.UPDATE_PROFILE_MUTATION](user) {
    //this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
  }

  @Mutation
  [Mutations.CREATE_JOB_MUTATION](job) {
    console.debug("CREATE JOB :", job);
    //this.isAuthenticated = true;
    this.job = job;
    this.errors = {};

    if (!this.jobs) {
      this.jobs = [];
    }
    if (job.id != null) {
      // update
      const currentItemIndex = this.jobs.findIndex(
        (item) => item.id === job.id
      );
      if (currentItemIndex === -1) {
        this.jobs.push(job);
      } else {
        this.jobs[currentItemIndex] = job;
      }
    } else {
      return false;
    }
  }
  @Mutation
  [Mutations.ADD_JOB_LANGUAGE_ITEM_MUTATION](languageData) {
    if (!this.job.languages) {
      this.job.languages = [];
    }
    if (languageData.id > 0) {
      // update
      const currentItemIndex = this.job.languages.findIndex(
        (item) => item.id === languageData.id
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.job.languages[currentItemIndex] = { ...languageData };
    } else {
      // is new only append
      delete languageData["id"];
      this.job.languages.push(languageData);
    }
  }

  @Mutation
  [Mutations.DELETE_JOB_LANGUAGE_ITEM_MUTATION](languageData) {
    if (!this.job.languages) {
      this.job.languages = [];
    }
    if (languageData.id > 0) {
      // update
      const currentItemIndex = this.job.languages.findIndex(
        (item) => item.id === languageData.id
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.job.languages[currentItemIndex] = { ...languageData };
      this.job.languages.splice([currentItemIndex], 1);
      // experienceData.splice([currentItemIndex], 1);
    }
  }
  @Mutation
  [Mutations.UPDATE_COMPANY_USERS](employee) {
    if (!this.user.users) {
      this.user.users = [];
    }
    if (employee.id != null) {
      this.user.users.push(employee);
      this.user.numberOfUsers++;
    }
    this.errors = {};
  }
  @Mutation
  [Mutations.DELETE_COMPANY_USERS_MUTATION](employee) {
    if (!this.user.users) {
      this.user.users = [];
    }
    if (employee != null) {
      // update
      const currentItemIndex = this.user.users.findIndex(
        (item) => item.id === employee
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.users[currentItemIndex] = { ...employee };
      this.user.users.splice([currentItemIndex], 1);
      // experienceData.splice([currentItemIndex], 1);
      this.user.numberOfUsers--;
    }
    this.errors = {};
    this.currentEmployeeId = null;
  }
  @Mutation
  [Mutations.SET_AUTH](user) {
    console.debug("SET_AUTH:", user);
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_JOBS](jobs) {
    this.jobs = jobs.data;
    this.jobListMeta = jobs.totalNumberOfRecords;
    this.errors = {};
  }

  @Mutation
  [Mutations.GET_JOBS_META_MUTATION](payload) {
    this.jobCurrentPage = payload.page;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_WORK_CATEGORY](categories) {
    this.categories = categories;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_WORK_TASKS](tasks) {
    this.tasks = tasks;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_WORK_SPECIALTY](specialties) {
    this.specialities = specialties;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_WORK_ROLES](roles) {
    this.roles = roles;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_CAREER_PATH](dataCareerPath) {
    this.careerPathSelected = dataCareerPath.data;
    this.careerPathSelected.forEach((item) => {
      this.careers[this.careers.findIndex((career) => career.id === item.id)] =
        {
          ...item,
        };
    });
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_CAREERS](Careers) {
    this.careers = Careers.data;
    this.errors = {};
  }

  @Mutation
  [Mutations.CAREER_PATH_COMPLETE](careerId) {
    debugger;
    // const completeCareerPath = this.careerPathSelected.concact(this.careers);
    const currentItemIndex = this.careerPathSelected.findIndex(
      (item) => item.id === careerId
    );
    if (currentItemIndex === -1) {
      debugger;
      this.completeCareerPath = {
        ...this.careers[careerId - 1],
        trainings: [],
        level: "Entry Level",
        percentageString: 0,
        percentage: 0,
      };
    } else {
      this.completeCareerPath = this.careerPathSelected.find(
        (x) => x.id == careerId
      );
    }
  }
  @Mutation
  [Mutations.TRAININGS_CAREER_PATH_COMPLETE](career) {
    debugger;
    // const completeCareerPath = this.careerPathSelected.concact(this.careers);
    const currentItemIndex = this.careerPathSelected.findIndex(
      (item) => item.id === career.Id
    );
    if (currentItemIndex === -1) {
      this.completeCareerPath = {
        ...this.careers[career.Id],
        trainings: career.trainings,
      };
    } else {
      this.completeCareerPath = this.careerPathSelected.find(
        (x) => x.id == career.Id
      );
    }
  }

  @Mutation
  [Mutations.SET_AUTH_TOKEN](token) {
    JwtService.saveToken(token);
    this.isAuthenticated = true;
  }

  @Mutation
  [Mutations.SET_APPLY_JOB](data) {
    console.log(data);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = {
      name: user.firstName,
      surname: `${user.middleName} ${user.lastName}`,
      email: user.contactInformation
        ? user.contactInformation.primaryEmail
        : null,
      password: null,
      api_token: JwtService.getToken(),
    };
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.jobs = [];
    this.job = {};
    this.categories = [];
    this.specialities = [];
    this.tasks = [];
    this.roles = [];
    this.careers = [];
    this.isAuthenticated = false;
    this.userRole = null;
    this.currentJobId = null;
    this.topTalent = [];
    this.errors = [];
    this.userRole = null;
    this.userSubscription = null;
    this.currentJobId = null;
    this.currentEmployeeId = null;
    this.topTalent = [] as Array<any>;
    this.talentList = [] as Array<any>;
    this.currentTalentDetails = {} as any;
    this.trainingsList = [] as Array<any>;
    this.trainingDetails = {} as any;
    this.careerPathSelected = {} as any;
    this.currentSubscriptions = {} as any;
    this.trainingListMeta = {} as any;
    this.trainingListProvider = {} as any;
    this.trainingListLevel = {} as any;
    this.trainingListFilters = {} as any;
    this.jobListMeta = 0;
    this.trainingCurrentPage = 0;
    this.jobCurrentPage = 0;
    this.UpdatedPasswordAlert = true;
    this.subscriptionSelected = null;
    JwtService.destroyToken();
    ApiService.setHeader();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("/authentication/login", credentials)
      .then(({ data }) => {
        if (data) {
          this.context.commit(Mutations.SET_AUTH_TOKEN, data.data);
          this.context.commit(Mutations.UPDATE_CURRENT_ROLE, data.data);
        } else {
          this.context.commit(Mutations.SET_ERROR, [
            {
              error: "Error autenticando usuario: Error de conexión.",
            },
          ]);
        }
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          this.context.commit(Mutations.SET_MESSAGE, response.data.message);
        } else {
          this.context.commit(Mutations.SET_ERROR, [
            {
              error: "Error autenticando usuario: Error de conexión.",
            },
          ]);
        }
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("/users", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.REGISTER_COMPANY](credentials) {
    return ApiService.post("/companies", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.REGISTER_EMPLOYEE](credentials) {
    return ApiService.post("/employees", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.UPDATE_COMPANY_USERS, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("/password/recover", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        console.log(response.data.errors);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ACTIVATION_ACCOUNT](payload) {
    return ApiService.post(`/users/confirm/${payload.token}`, {})
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.RESET_PASSWORD](payload) {
    return ApiService.post("/password/reset", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.RESET_EMAIL_TOKEN](payload) {
    return ApiService.post("/users/confirm/change/email", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CHANGE_PASSWORD](data) {
    return ApiService.post("users/reset/password", data)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CHANGE_EMAIL](data) {
    return ApiService.post("users/reset/email", data)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.DEACTIVATE_ACCOUNT](deactivate) {
    return ApiService.delete2("/users/user", deactivate)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(
        this.currentUserRole === "Talent" ? "/profile" : "/companies/company"
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data.data);
          this.context.commit(Mutations.UPDATE_CURRENT_SUBSCRIPTION, data.data);
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR, "Not authenticated");
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.GET_JOBS](request) {
    ApiService.setHeader();
    return ApiService.post("positions/fetch", request)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_JOBS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.GET_JOBS_META](payload) {
    return ApiService.get(
      `/marketplace/items/Position`,
      `?page=${payload.page}&meta=*&limit=${payload.limit}`
    )
      .then((data) => {
        this.context.commit(Mutations.GET_JOBS_META_MUTATION, {
          jobs: data.data,
          page: payload.page,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  //UPDATE API TO ACCEPT "positions/company?skip=" + request.skip + "&take=" + request.take
  @Action
  [Actions.GET_COMPANY_JOBS](request) {
    ApiService.setHeader();
    return ApiService.post("positions/company", request)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_JOBS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.APPLY_JOB](jobData) {
    return ApiService.post("/talent/apply", jobData)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_APPLY_JOB, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.TAKE_TRAINING_REQUEST](trainingData) {
    return ApiService.post("/marketplace/takeTraining", trainingData)
      .then()
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.TAKE_SUBSCRIPTION_REQUEST](Subscription) {
    return ApiService.post("/companies/takeSubscription", Subscription)
      .then()
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.GET_JOB_DETAILS](positionId) {
    return ApiService.get(`positions/${positionId}`)
      .then(({ data }) => {
        this.context.commit(Mutations.CREATE_JOB_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CREATE_JOB](job) {
    return ApiService.post("/positions", job)
      .then(({ data }) => {
        this.context.commit(Mutations.CREATE_JOB_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.WORK_CATEGORY]() {
    return ApiService.get("/workforceframework/categories")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_WORK_CATEGORY, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.WORK_SPECIALITY](categories) {
    return ApiService.post(
      "/workforceframework/categories/specialties",
      categories
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_WORK_SPECIALTY, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.WORK_TASK](specialties) {
    return ApiService.post("/workforceframework/specialties/tasks", specialties)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_WORK_TASKS, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.WORK_ROLE](tasks) {
    return ApiService.post("/workforceframework/tasks/roles", tasks)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_WORK_ROLES, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.CAREER_PATH](profileId) {
    return ApiService.post("/marketplace/careerscore", profileId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CAREER_PATH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.GET_CAREER_TRAININGS](careerId) {
    return ApiService.get(`marketplace/careers/${careerId.careerId}`)
      .then(({ data }) => {
        debugger;
        this.context.commit(
          Mutations.TRAININGS_CAREER_PATH_COMPLETE,
          data.data
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CAREERS](profileId) {
    return ApiService.post("/marketplace/careers", profileId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CAREERS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_PICTURE_PROFILE](picture) {
    return ApiService.post("/azurefile/uploadfile", picture)
      .then(({ data }) => {
        this.context.commit(Mutations.UPDATE_PROFILE_MUTATION, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_PROFILE](profile) {
    return ApiService.put("/profile", profile)
      .then(({ data }) => {
        this.context.commit(Mutations.UPDATE_PROFILE_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_COMPANY](company) {
    return ApiService.put("/companies", company)
      .then(({ data }) => {
        this.context.commit(Mutations.UPDATE_PROFILE_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_WORK_EXPERIENCE_ITEM](experienceData) {
    // update the state
    this.context.commit(
      Mutations.UPDATE_WORK_EXPERIENCE_ITEM_MUTATION,
      experienceData
    );
    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }

  @Action
  [Actions.DELETE_WORK_EXPERIENCE_ITEM](experienceData) {
    // update the state
    this.context.commit(
      Mutations.DELETE_WORK_EXPERIENCE_ITEM_MUTATION,
      experienceData
    );

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }

  @Action
  [Actions.DELETE_COMPANY_USERS](employeeId) {
    return ApiService.delete(`employees/${employeeId}`)
      .then(() => {
        this.context.commit(
          Mutations.DELETE_COMPANY_USERS_MUTATION,
          employeeId
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.UPDATE_EDUCATION_ITEM](educationData) {
    // update the state
    this.context.commit(
      Mutations.UPDATE_EDUCATION_ITEM_MUTATION,
      educationData
    );

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }
  @Action
  [Actions.DELETE_EDUCATION_ITEM](educationData) {
    // update the state
    this.context.commit(
      Mutations.DELETE_EDUCATION_ITEM_MUTATION,
      educationData
    );

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }

  @Action
  [Actions.UPDATE_CERTIFICATION_ITEM](certificationData) {
    // update the state
    this.context.commit(
      Mutations.UPDATE_CERTIFICATION_ITEM_MUTATION,
      certificationData
    );

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }

  @Action
  [Actions.DELETE_CERTIFICATION_ITEM](certificationData) {
    // update the state
    this.context.commit(
      Mutations.DELETE_CERTIFICATION_ITEM_MUTATION,
      certificationData
    );

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }

  @Action
  [Actions.DELETE_JOB](positionId) {
    return ApiService.delete(`positions/${positionId}`)
      .then(() => {
        this.context.commit(Mutations.DELETE_JOB_MUTATION, positionId);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_COURSE_ITEM](courseData) {
    // update the state
    this.context.commit(Mutations.UPDATE_COURSE_ITEM_MUTATION, courseData);

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }
  @Action
  [Actions.DELETE_COURSE_ITEM](courseData) {
    // update the state
    this.context.commit(Mutations.DELETE_COURSE_ITEM_MUTATION, courseData);

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }
  @Action
  [Actions.UPDATE_LANGUAGE_ITEM](languageData) {
    // update the state
    this.context.commit(Mutations.UPDATE_LANGUAGE_ITEM_MUTATION, languageData);

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }
  @Action
  [Actions.DELETE_LANGUAGE_ITEM](languageData) {
    // update the state
    this.context.commit(Mutations.DELETE_LANGUAGE_ITEM_MUTATION, languageData);

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }
  @Action
  [Actions.UPDATE_RECOMMENDATION_ITEM](recommendationData) {
    // update the state
    this.context.commit(
      Mutations.UPDATE_RECOMMENDATION_ITEM_MUTATION,
      recommendationData
    );

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }
  @Action
  [Actions.DELETE_RECOMMENDATION_ITEM](recommendationData) {
    // update the state
    this.context.commit(
      Mutations.DELETE_RECOMMENDATION_ITEM_MUTATION,
      recommendationData
    );

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }
  @Action
  [Actions.UPDATE_JOB_NIST_NICE](tasks) {
    this.job.workTasks = tasks;
    this.context.dispatch(Actions.UPDATE_JOB, this.job);
  }
  @Action
  [Actions.UPDATE_PROFILE_NIST_NICE](tasks) {
    this.user.workSpecialties = this.specialities
      .filter((item) => item.selected)
      .map((item) => item.id);
    this.user.workCategories = this.categories
      .filter((item) => item.selected)
      .map((item) => item.id);
    this.user.workTasks = tasks;
    this.user.isBegginer = false;
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }

  @Mutation
  [Mutations.UPDATE_NISTNICE_SPECIALITY](item) {
    console.debug("UPDATE_NISTNICE_SPECIALITY:", item, this.specialities);
    const idx = this.specialities.findIndex((entity) => entity.id === item.id);
    if (idx > -1) {
      this.specialities[idx] = { ...item };
    }
  }

  @Mutation
  [Mutations.UPDATE_NISTNICE_TASK](item) {
    console.debug("UPDATE_NISTNICE_TASK:", item, this.tasks);
    const idx = this.tasks.findIndex((entity) => entity.id === item.id);
    if (idx > -1) {
      this.tasks[idx] = { ...item };
    }
  }

  @Mutation
  [Mutations.MREGE_NIST_NICE](payload) {
    console.debug("MREGE_NIST_NICE:", payload);
    if (payload.data && payload.data.length > 0) {
      payload.data.forEach((id) => {
        const idx = this[payload.stateName].findIndex(
          (entity) => entity.id === id
        );
        if (idx > -1) {
          this[payload.stateName][idx].selected = true;
        }
      });
    }
  }

  @Action
  [Actions.DELETE_PICTURE_PROFILE]() {
    return ApiService.delete("/azurefile/deletefile").catch(() => {
      this.context.commit(Mutations.SET_ERROR, "Not task");
    });
  }
  @Action
  [Actions.UPDATE_JOB](job) {
    return ApiService.put("/positions", job)
      .then(({ data }) => {
        this.context.commit(Mutations.CREATE_JOB_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.ADD_JOB_CERTIFICATION_ITEM](certificationData) {
    // update the state
    this.context.commit(
      Mutations.ADD_JOB_CERTIFICATION_ITEM_MUTATION,
      certificationData
    );

    // update on API
    this.context.dispatch(Actions.UPDATE_JOB, this.job);
  }

  @Action
  [Actions.DELETE_JOB_CERTIFICATION_ITEM](certificationData) {
    // update the state
    this.context.commit(
      Mutations.DELETE_JOB_CERTIFICATION_ITEM_MUTATION,
      certificationData
    );

    // update on API
    this.context.dispatch(Actions.UPDATE_JOB, this.job);
  }
  @Action
  [Actions.ADD_JOB_LANGUAGE_ITEM](languageData) {
    // update the state
    this.context.commit(Mutations.ADD_JOB_LANGUAGE_ITEM_MUTATION, languageData);

    // update on API
    this.context.dispatch(Actions.UPDATE_JOB, this.job);
  }
  @Action
  [Actions.DELETE_JOB_LANGUAGE_ITEM](languageData) {
    // update the state
    this.context.commit(
      Mutations.DELETE_JOB_LANGUAGE_ITEM_MUTATION,
      languageData
    );

    // update on API
    this.context.dispatch(Actions.UPDATE_JOB, this.job);
  }
  @Action
  [Actions.GET_TOP_TALENT_JOB](jobId) {
    // update the state
    return ApiService.get(`positions/company/toptalent`, `?id=${jobId}`)
      .then((data) => {
        this.context.commit(Mutations.GET_TOP_TALENT_JOB_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.GET_TOP_TALENT_JOB_FILTER](payload) {
    // update the state
    return ApiService.post("positions/company/toptalent", payload)
      .then((data) => {
        this.context.commit(Mutations.GET_TOP_TALENT_JOB_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_TALENT_LIST](filters) {
    return ApiService.post("/talent/fetch", filters)
      .then((data) => {
        this.context.commit(Mutations.GET_TALENT_LIST_MUTATION, data.data);
        return data;
      })
      .catch((response) => {
        this.context.commit(Mutations.SET_ERROR, response);
        return response;
      });
  }
  @Action
  [Actions.CONTACT_TALENT](message) {
    return ApiService.post("/companies/contact/talent", message).catch(
      ({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      }
    );
  }

  @Action
  [Actions.GET_CURRENT_TALENT_DETAILS](talentId) {
    return ApiService.get(`talent/${talentId}`)
      .then((data) => {
        this.context.commit(
          Mutations.UPDATE_CURRENT_TALENT_DETAILS,
          data.data?.data
        );
      })
      .catch((response) => {
        this.context.commit(Mutations.SET_ERROR, response);
      });
  }
  @Action
  [Actions.GET_CURRENT_SUBSCRIPTION]() {
    return ApiService.get("/subscription")
      .then((data) => {
        this.context.commit(
          Mutations.GET_CURRENT_SUBSCRIPTION_MUTATION,
          data.data.data
        );
      })
      .catch((response) => {
        this.context.commit(Mutations.SET_ERROR, response);
      });
  }
  @Action
  [Actions.GET_SUBSCRIPTION_URL](data) {
    return ApiService.post("/webhook/checkout", data)
      .then((data) => {
        this.context.commit(Mutations.GET_SUBSCRIPTION_URL_MUTATION, data.data);
        return data.data;
      })
      .catch((response) => {
        this.context.commit(Mutations.SET_ERROR, response);
        return response;
      });
  }

  @Action
  [Actions.GET_TRAININGS_LIST](payload) {
    const searchFilter = {
      _and: [
        {
          _or: [
            {
              title: {
                _icontains: payload.searchText,
              },
            },
            {
              description: {
                _icontains: payload.searchText,
              },
            },
          ],
        },
      ],
    };
    const providerFilter = {
      _and: [
        {
          provider: {
            _icontains: payload.providerText,
          },
        },
      ],
    };
    const levelFilter = {
      _and: [
        {
          Level: {
            _icontains: payload.levelText,
          },
        },
      ],
    };
    const typeFilterOptions = [] as any;
    payload.typeSelected.forEach((item) => {
      typeFilterOptions.push({
        type: {
          _eq: item,
        },
      });
    });
    const typeFilter = {
      _and: [
        {
          _or: typeFilterOptions,
        },
      ],
    };
    const trainingFilterOptions = [] as any;
    if (payload.searchText && payload.searchText.trim() !== "") {
      trainingFilterOptions.push(searchFilter);
    }
    if (payload.providerText && payload.providerText.trim() !== "") {
      trainingFilterOptions.push(providerFilter);
    }

    if (payload.levelText && payload.levelText.trim() !== "") {
      trainingFilterOptions.push(levelFilter);
    }
    if (payload.typeSelected && payload.typeSelected.length > 0) {
      trainingFilterOptions.push(typeFilter);
    }
    const trainingFilter = {
      _and: [
        {
          _and: trainingFilterOptions,
        },
      ],
    };
    return ApiService.get(
      `/marketplace/items/training`,
      `?page=${payload.page}&meta=*&limit=${
        payload.limit
      }&filter=${JSON.stringify(trainingFilter)}`
    )
      .then((data) => {
        this.context.commit(Mutations.GET_TRAININGS_LIST_MUTATION, {
          trainings: data.data,
          page: payload.page,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.GET_TRAININGS_LIST_FILTER](payload) {
    debugger;
    const searchFilter = {
      _and: [
        {
          _or: [
            {
              title: {
                _icontains: payload.searchText,
              },
            },
            {
              description: {
                _icontains: payload.searchText,
              },
            },
          ],
        },
      ],
    };
    const providerFilter = {
      _and: [
        {
          provider: {
            _icontains: payload.providerText,
          },
        },
      ],
    };
    const levelFilter = {
      _and: [
        {
          Level: {
            _icontains: payload.levelText,
          },
        },
      ],
    };
    const typeFilterOptions = [] as any;
    payload.typeSelected.forEach((item) => {
      typeFilterOptions.push({
        type: {
          _eq: item,
        },
      });
    });
    const typeFilter = {
      _and: [
        {
          _or: typeFilterOptions,
        },
      ],
    };
    const trainingFilterOptions = [] as any;
    if (payload.searchText && payload.searchText.trim() !== "") {
      trainingFilterOptions.push(searchFilter);
    }
    if (payload.providerText && payload.providerText.trim() !== "") {
      trainingFilterOptions.push(providerFilter);
    }

    if (payload.levelText && payload.levelText.trim() !== "") {
      trainingFilterOptions.push(levelFilter);
    }
    if (payload.typeSelected && payload.typeSelected.length > 0) {
      trainingFilterOptions.push(typeFilter);
    }
    const trainingFilter = {
      _and: [
        {
          _and: trainingFilterOptions,
        },
      ],
    };
    return ApiService.get(
      `/marketplace/items/training`,
      `?page=${payload.page}&meta=*&limit=${
        payload.limit
      }&filter=${JSON.stringify(trainingFilter)}&groupBy=${
        payload.groupBy
      }&aggregate=${payload.aggregate}`
    )
      .then((data) => {
        this.context.commit(Mutations.GET_TRAININGS_LIST_FILTER_MUTATION, {
          trainings: data.data,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.GET_TRAININGS_LIST_FILTER_PROVIDER](payload) {
    return ApiService.get(
      `/marketplace/items/training`,
      `?groupBy=${payload.groupBy}`
    )
      .then((data) => {
        this.context.commit(
          Mutations.GET_TRAININGS_LIST_FILTER_PROVIDER_MUTATION,
          {
            trainings: data.data,
          }
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.GET_TRAININGS_LIST_FILTER_LEVEL](payload) {
    return ApiService.get(
      `/marketplace/items/training`,
      `?groupBy=${payload.groupBy}`
    )
      .then((data) => {
        this.context.commit(
          Mutations.GET_TRAININGS_LIST_FILTER_LEVEL_MUTATION,
          {
            trainings: data.data,
          }
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.GET_TRAINING_DETAILS](parameters) {
    return ApiService.post(
      `/marketplace/items/training/${parameters.trainingId}`,
      parameters.parameter
    )
      .then((data) => {
        this.context.commit(Mutations.GET_TRAINING_DETAILS_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.GET_COURSE_DETAILS](courseId) {
    return ApiService.get(`/marketplace/items/training_courses/${courseId}`)
      .then((data) => {
        this.context.commit(Mutations.GET_COURSE_DETAILS_MUTATION, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.COMPLETE_TRAINING](trainingId) {
    return ApiService.get(`/marketplace/completeTraining/${trainingId}`)
      .then((data) => {
        this.context.commit(Mutations.COMPLETE_TRAINING_MUTATION, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.UPDATE_TOPTALENT](payload) {
    return ApiService.put("/positions/company/UpdateTopTalent", payload)
      .then()
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
